import React, { useEffect, useState, Fragment } from 'react';
import useBookingUserData from '../components/Bookings/hooks/useBookingUserData';
// import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import FeaturedList from '../components/FeaturedList';
import { FEATURED_TYPES, NAV_OPTIONS, STATUS, STORE_TYPES, CITIES_USING_TOP_PICKS } from '../utils/constants';
import { Typography } from '@material-ui/core';
import SimpleCard from '../components/SimpleCard';
import SkeletonSimpleCard from '../components/SkeletonSimpleCard';
import api from '../utils/api';
import { parseIdFromLink, allStoresRedirect, filterFeaturedList, deAbbreviate } from '../utils/helpers';
import { useStateValue } from '../state';
// import Carousel from '../components/Carousel';
import CarouselManaged from '../components/CarouselManaged';
import { Helmet } from 'react-helmet';
import { storeFrontBannerData } from '../utils/example-data';

const useStyles = makeStyles((theme) => ({
  root: {},
  topBanner: {
    display: 'flex',
    backgroundColor: theme.palette.secondary.main,
    height: 400,
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerWrapper: {
    margin: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentContainer: {
    width: '100%',
    maxWidth: theme.screen.maxWidth,
    display: 'flex',
    flexDirection: 'column',
  },
  gridTitle: {
    margin: theme.spacing(2),
    fontSize: '2rem',
    color: theme.palette.primary.main,
    fontWeight: '700',
  },
  storeGrid: {
    margin: theme.spacing(2),
    marginBottom: theme.spacing(10),
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridGap: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
  emptyList: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    height: 200,
  },
}));

const getStores = async (type, id) => {
  return api
    .getStores(type, id, true)
    .then((res) => res)
    .catch(() => {
      throw STATUS.ERROR;
    });
};

const getStoreRanks = async (id) => {
  return (
    api
      // .getStoreRanks(id) //Changed to landing Projection becuase /location/${id} was overfetching (+6Mb for loc 90)
      // .then((res) => res.data._embedded)
      .getLandingProjection(id)
      .then((res) => res.data)
      .catch((e) => {
        throw STATUS.ERROR;
      })
  );
};

const getAllStoreRanks = async (id) => {
  return api
    .getAllStoreRanksByLocation(id)
    .then((res) => res.data._embedded.allStoreRanks)
    .catch((e) => {
      throw STATUS.ERROR;
    });
};

function StoresView(props) {
  const classes = useStyles();

  //========Bookings
  const { fetchAndBuildList, allowBookings } = useBookingUserData();

  const [allStores, setAllStores] = useState([]);
  const [allRanks, setAllRanks] = useState([]);
  const [featuredStores, setFeaturedStores] = useState([]);
  const [recommended, setRecommended] = useState([]);
  const [topRatedStores, setTopRatedStores] = useState([]);

  const [loadingStores, setLoadingStores] = useState(true);
  const [loadingFeatured, setLoadingFeatured] = useState(true);

  const [{ location }] = useStateValue();
  const currCity = decodeURI(location.city);
  const currProvince = location.province;

  const currCityID = location.link;
  const currLocationID = parseIdFromLink(location.link);

  // ao: sdaw-1162 - redirect to the correct province name
  const urlProv = window.location.href.split('/')[4] && window.location.href.split('/')[4].toLowerCase();
  //if (urlProv !== location.province.replace(/ /gi, '-').toLowerCase()) {
  if (urlProv.length === 2) {
    const abbrProvince = urlProv;
    const provFullName = deAbbreviate(abbrProvince.toUpperCase());
    const urlCity = window.location.href.split('/')[4] && window.location.href.split('/')[5].toLowerCase();

    window.location.href = `/deliveries/${provFullName.replace(/ /gi, '-').toLowerCase()}/${urlCity
      .replace(/ /gi, '-')
      .toLowerCase()}`;
  }

  useEffect(() => {
    let mounted = true;
    const isBookingsPermitted = allowBookings(currCityID);
    setLoadingStores(true);
    setLoadingFeatured(true);

    Promise.all([
      getStores(STORE_TYPES[0], location.link),
      getAllStoreRanks(currLocationID),
      getStoreRanks(currLocationID),
    ])
      .then((res) => {
        const [allStoresRes, allStoreRanksRes, ranksRes] = res;
        const unOrderedStores = allStoresRes?.concat().sort((first, second) => first.name.localeCompare(second.name));
        const topStores = allStoresRes
          ?.concat()
          .filter((store) => store.numOfReviews > 0)
          .sort((first, second) =>
            second.avgRating - first.avgRating !== 0
              ? second.avgRating - first.avgRating
              : second.numOfReviews - first.numOfReviews !== 0
              ? second.numOfReviews - first.numOfReviews
              : first.name.localeCompare(second.name)
          )
          .slice(0, 15);
        const ranksForAllStores = allStoreRanksRes
          ?.filter((x) => x.store.storeType === 'Storefront')
          .sort((store1, store2) =>
            store1.rank - store2.rank !== 0
              ? store1.rank - store2.rank
              : store1.store.name.localeCompare(store2.store.name)
          )
          .map((store) => store.store);

        //===========================
        const featuredRanks = filterFeaturedList('store', ranksRes.featuredStoreRanks, unOrderedStores).map((store) => {
          return store.store;
        });

        const recommendedRanks = filterFeaturedList('store', ranksRes.recommendedRanks, unOrderedStores)
          .filter((store) => store.store.storeType === 'Storefront')
          .map((store) => {
            return store.store;
          });

        if (mounted) {
          setAllStores(unOrderedStores);
          setAllRanks(ranksForAllStores);
          setTopRatedStores(topStores);
          setLoadingStores(false);
        }
        if (mounted && !isBookingsPermitted) {
          setFeaturedStores(featuredRanks);
          setRecommended(recommendedRanks);
          setLoadingFeatured(false);
        }
      })
      .catch((e) => {
        console.log(e);
      });

    if (isBookingsPermitted) {
      // fetchAndFilterBookings(currCity, currProvince, 'Storefront').then((lists) => {
      fetchAndBuildList(new Date(), 0, currCity, currProvince, 'Storefront').then((lists) => {
        const { popularStores, platinumStores } = lists;
        if (mounted) {
          setFeaturedStores(platinumStores);
          setRecommended(popularStores);
          setLoadingFeatured(false);
        }
      });
    }
    return () => {
      mounted = false;
    };
    //eslint-disable-next-line
  }, [location]);

  return (
    <div className={classes.root}>
      <Helmet>
        <title>{`Weed Dispensaries ${currCity} - Find Cannabis Today | S7DAW`}</title>
        <link rel="canonical" href={window.location.href} />
        <meta
          name="description"
          content={`Find the best weed dispensaries in ${currCity}, ${currProvince}. Looking for cannabis in ${currCity}? Find marijuana, CBD oil, weed seeds, edibles and more, locally. `}
        />
      </Helmet>
      {/* <Carousel
        banners={storeFrontBannerData}
        type={'StoreFronts'}
        override={`Weed Dispensaries ${currCity}`.toUpperCase()}
      ></Carousel> */}
      <CarouselManaged
        id="topBanners"
        banners={storeFrontBannerData}
        type={'StoreFronts'}
        override={`Weed Dispensaries ${currCity}`.toUpperCase()}
      />
      {/* <Typography variant="h6" component="h1" >{`Weed Dispensaries ${currCity}`}</Typography> */}
      <div className={classes.containerWrapper}>
        <div className={classes.contentContainer}>
          <FeaturedList
            loading={loadingFeatured}
            index={1}
            listData={featuredStores}
            title="Platinum Stores"
            image="storefronts"
            type={FEATURED_TYPES.STORE}
            viewAllPath="NONE"
            handleNavBarChange={() => {}}
            location={location}
          />
          <FeaturedList
            loading={loadingFeatured}
            index={3}
            listData={recommended}
            title={CITIES_USING_TOP_PICKS.includes(currCity) ? CITIES_USING_TOP_PICKS[0] : 'Most Popular Storefronts'}
            image="storefronts"
            type={FEATURED_TYPES.STORE}
            viewAllPath="NONE"
            handleNavBarChange={() => {}}
            location={location}
          />
          <FeaturedList
            loading={loadingStores}
            index={2}
            listData={topRatedStores}
            title="Top Rated"
            image="storefronts"
            type={FEATURED_TYPES.STORE}
            viewAllPath="NONE"
            handleNavBarChange={() => {}}
            location={location}
          />
          <Typography variant="h2" className={classes.gridTitle}>
            ALL STOREFRONTS
          </Typography>
          {!loadingStores && allStores.length === 0 && (
            <Typography className={classes.emptyList}>
              At this time we are working to bring on partnerships with your local retailers to service this area.
              Please check back later.
            </Typography>
          )}
          <div className={classes.storeGrid}>
            {!loadingStores &&
              allRanks.length > 0 &&
              allRanks.map((store, index) => {
                return (
                  <SimpleCard
                    key={index}
                    item={store}
                    itemPath={`${NAV_OPTIONS.find((option) => option.name === 'Storefronts').path}/${allStoresRedirect(
                      location,
                      store
                    )}`}
                    type={FEATURED_TYPES.STORE}
                  ></SimpleCard>
                );
              })}
            {!loadingStores &&
              allStores.length > 0 &&
              allStores
                .filter((store) => {
                  return !allRanks.some(
                    (rankedStore) =>
                      parseIdFromLink(store._links.self.href.replace('{?projection}', '')) ===
                      parseIdFromLink(rankedStore._links.self.href.replace('{?projection}', ''))
                  );
                })
                .map((store, index) => {
                  return (
                    <SimpleCard
                      key={index}
                      item={store}
                      itemPath={`${
                        NAV_OPTIONS.find((option) => option.name === 'Storefronts').path
                      }/${allStoresRedirect(location, store)}`}
                      type={FEATURED_TYPES.STORE}
                    ></SimpleCard>
                  );
                })}
            {loadingStores && (
              <Fragment>
                <SkeletonSimpleCard></SkeletonSimpleCard>
                <SkeletonSimpleCard></SkeletonSimpleCard>
                <SkeletonSimpleCard></SkeletonSimpleCard>
              </Fragment>
            )}
          </div>
          {/* Implement lazy loading for store cards here once the API is hooked up */}
        </div>
      </div>
    </div>
  );
}

export default StoresView;
