import { makeStyles } from '@material-ui/styles';
/* Theme / Styling for CLINICS view 
[] TODO, verify the same and move over to DELIVERIES/STORES to DRY Code */

export default makeStyles((theme) => ({
  root: {},
  topBanner: {
    display: 'flex',
    backgroundColor: theme.palette.secondary.main,
    height: 400,
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerWrapper: {
    margin: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentContainer: {
    width: '100%',
    maxWidth: theme.screen.maxWidth,
    display: 'flex',
    flexDirection: 'column',
  },
  gridTitle: {
    margin: theme.spacing(2),
    fontSize: '2rem',
    color: theme.palette.primary.main,
    fontWeight: '700',
  },
  storeGrid: {
    margin: theme.spacing(2),
    marginBottom: theme.spacing(10),
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridGap: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
  emptyList: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    height: 200,
  },
}));
