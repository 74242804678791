import React, { useState, useEffect } from 'react';
import useBookingUserData from '../components/Bookings/hooks/useBookingUserData';
import { makeStyles, Typography } from '@material-ui/core';
import CarouselManaged from '../components/CarouselManaged';
import FeaturedList from '../components/FeaturedList';
import {
  FEATURED_TYPES,
  NAV_OPTIONS,
  STATUS,
  // STORE_TYPES
  CITIES_USING_TOP_PICKS,
} from '../utils/constants';
import api from '../utils/api';
import { useStateValue } from '../state';
import { parseIdFromLink, filterFeaturedList } from '../utils/helpers';
import { Helmet } from 'react-helmet';

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentInnerContainer: {
    width: '100%',
    maxWidth: theme.screen.maxWidth,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const getRanks = async (id) => {
  return api
    .getLandingProjection(id)
    .then((res) => res.data)
    .catch(() => {
      throw STATUS.ERROR;
    });
};

const getDealRanks = async (id) => {
  return api
    .getDealRanksByLocation(id)
    .then((res) => res.data)
    .catch(() => {
      throw STATUS.ERROR;
    });
};

const getBrands = async () => {
  return api
    .getBrands(true)
    .then((res) => res)
    .catch(() => {
      throw STATUS.ERROR;
    });
};

const getNationWideDeliveries = async () => {
  return api
    .getNationWideDeliveries()
    .then((res) => res)
    .catch(() => {
      throw STATUS.ERROR;
    });
};

const getFeaturedProducts = async () => {
  return api
    .getFeaturedProducts()
    .then((res) => res.filter((feat) => feat.product && feat.product.removeFromMenu !== true))
    .catch(() => {
      throw STATUS.ERROR;
    });
};

const getAllDeals = async (id) => {
  //Used to filter the deal list
  const publishedOnly = true;
  return await api
    .getDealsByLocation(id, publishedOnly)
    .then((res) => res)
    .catch(() => {
      throw STATUS.ERROR;
    });
};

const getAllClinics = async () => {
  return api
    .getStores('Clinic', null, true)
    .then((res) => res)
    .catch(() => {
      throw STATUS.ERROR;
    });
};

const fetchHref = async (href, dataKey) => {
  return api
    .fetchHref(href, dataKey)
    .then((res) => {
      return res;
    })
    .catch((e) => {
      throw STATUS.ERROR;
    });
};

function LandingPageView(props) {
  const classes = useStyles();

  const { fetchBookingsByDates, filterBookingsByLocation, filterBookingsByType, createBookingsLists, allowBookings } =
    useBookingUserData();

  const { handleNavBarChange } = props;

  const [stores, setStores] = useState([]);
  const [deliveries, setDeliveries] = useState([]);
  const [platinumClinics, setPlatinumClinics] = useState([]);
  const [recommended, setRecommended] = useState([]);
  const [nationWideDeliveries, setNationWideDeliveries] = useState([]);
  const [deals, setDeals] = useState([]);
  const [brands, setBrands] = useState([]);
  const [products, setProducts] = useState([]);

  const [{ location }] = useStateValue();
  const currCityID = location.link;
  const currCity = location.city;
  const currProvince = location.province;

  const [loadingStores, setLoadingStores] = useState(true);
  const [loadingDeliveries, setLoadingDeliveries] = useState(true);
  const [loadingClinics, setLoadingClinics] = useState(true);
  const [loadingRecommended, setLoadingRecommended] = useState(true);
  const [loadingNationWideDeliveries, setLoadingNationWideDeliveries] = useState(true);
  const [loadingDeals, setLoadingDeals] = useState(true);
  const [loadingBrands, setLoadingBrands] = useState(true);
  const [loadingProds, setLoadingProds] = useState(true);

  useEffect(() => {
    setLoadingStores(true);
    setLoadingNationWideDeliveries(true);
    setLoadingDeliveries(true);
    setLoadingClinics(true);
    setLoadingRecommended(true);
    setLoadingDeals(true);
    setLoadingBrands(true);
    setLoadingProds(true);

    const isBookingsPermitted = allowBookings(currCityID);
    if (location.link) {
      const locationID = parseIdFromLink(location.link);
      getRanks(locationID)
        .then((rawRanks) => {
          const featuredDeliveries = filterFeaturedList('delivery', rawRanks.featuredDeliveryRanks, null, {
            city: currCity,
            province: currProvince,
          })
            .filter((store) => store.delivery.storeType === 'Delivery' && store.localDelivery)
            .map((store) => {
              return { ...store.delivery, slug: store.storeSlug };
            });
          const recommendedDelivery = filterFeaturedList('store', rawRanks.recommendedRanks, null, {
            city: currCity,
            province: currProvince,
          })
            .filter(
              (store) =>
                store.store.storeType === 'Delivery' && store.store.published === true && store.store.localDelivery
            )
            .map((store) => {
              return { ...store.store, slug: store.storeSlug };
            });
          const featuredStores = filterFeaturedList('store', rawRanks.featuredStoreRanks, null, {
            city: currCity,
            province: currProvince,
          }).map((store) => {
            return { ...store.store, slug: store.storeSlug };
          });

          if (isBookingsPermitted) {
            fetchBookingsByDates(new Date(), 0).then((bookings) => {
              const bookingsInLocation = filterBookingsByLocation(bookings, location);
              const storeBookings = filterBookingsByType(bookingsInLocation, 'Storefront').filter(
                (booking) => booking.list === 'platinum'
              );
              const deliveryBookings = filterBookingsByType(bookingsInLocation, 'Delivery');
              const deliveryList = createBookingsLists(deliveryBookings);

              setStores(storeBookings.map((x) => x.store));
              setDeliveries(deliveryList.platinumStores);
              setRecommended(deliveryList.popularStores);
            });
          } else {
            setStores(featuredStores);
            setDeliveries(featuredDeliveries);
            setRecommended(recommendedDelivery);
          }

          setLoadingDeliveries(false);
          setLoadingRecommended(false);
          setLoadingStores(false);
        })
        .catch((err) => {
          console.error('Hit Error', err);
          setLoadingStores(false);
          setLoadingDeliveries(false);
          setLoadingDeals(false);
          setLoadingRecommended(false);
        });
      getDealRanks(locationID) //[] This should exist in the landing projection, change in next re-factor w.async / await
        .then((res) => {
          const featDealRanks = res._embedded.featuredDealRanks;
          getAllDeals(locationID).then((allDeals) => {
            const publicList = filterFeaturedList('deal', featDealRanks, allDeals);
            const featuredDeals = publicList
              ? publicList.map((store) => {
                  return { ...store.deal, slug: store.dealSlug };
                })
              : [];
            setDeals(featuredDeals);
            setLoadingDeals(false);
          });
        })
        .catch((err) => {
          setLoadingDeals(false);
        });
    }
    getBrands()
      .then((res) => {
        setBrands(
          res
            .concat()
            .filter((brand) => brand.latestRank !== null && brand.latestRank !== undefined)
            .sort((first, second) =>
              first.latestRank - second.latestRank !== 0
                ? first.latestRank - second.latestRank
                : first.name.localeCompare(second.name)
            )
        );

        setLoadingBrands(false);
      })
      .catch((err) => {
        setLoadingBrands(false);
      });

    getNationWideDeliveries()
      .then((res) => {
        setNationWideDeliveries(
          res
            .concat()
            .filter((store) => store.mailOutLatestRank !== null && store.mailOutLatestRank !== undefined)
            .sort((x, y) => x.mailOutLatestRank - y.mailOutLatestRank)
        );
        setLoadingNationWideDeliveries(false);
      })
      .catch((err) => {
        setLoadingNationWideDeliveries(false);
      });

    getAllClinics()
      .then(async (rawClinics) => {
        const apiEndpoint = 'featuredStoreRanks';
        const listLinks = rawClinics.map((x) => x._links[apiEndpoint].href);
        let featClinics = await Promise.all(listLinks.map((req) => fetchHref(req, apiEndpoint)));
        featClinics = featClinics.flat();
        featClinics = filterFeaturedList('store', featClinics, rawClinics);
        featClinics = featClinics.map((x) => {
          return x.store;
        });
        //iterate and fetch fatured Clinics
        setPlatinumClinics(featClinics);
        setLoadingClinics(false);
      })
      .catch((err) => {
        setLoadingClinics(false);
      });

    getFeaturedProducts()
      .then((res) => {
        setProducts(res.filter((prod) => prod.brand.published === true));
        setLoadingProds(false);
      })
      .catch((err) => {
        setLoadingProds(false);
      });
    //eslint-disable-next-line
  }, [location]);

  const getNavBarIndex = (type) => {
    return NAV_OPTIONS.findIndex((option) => option.name === type);
  };

  const flower = products
    .filter((prod) => prod.type === 'Flower' && prod.product.categories.category === 'Flower')
    .sort((x, y) => x.rank - y.rank);

  const edibles = products
    .filter((prod) => prod.type === 'Edibles' && prod.product.categories.category === 'Edibles')
    .sort((x, y) => x.rank - y.rank);

  const concentrates = products
    .filter((prod) => prod.type === 'Concentrates' && prod.product.categories.category === 'Concentrates')
    .sort((x, y) => x.rank - y.rank);

  const cbd = products.filter((prod) => prod.type === 'CBD').sort((x, y) => x.rank - y.rank);

  const vapepens = products
    .filter((prod) => prod.type === 'Vape Pens' && prod.product.categories.category === 'Vape Pens')
    .sort((x, y) => x.rank - y.rank);

  const bannerData = [
    {
      name: 'stores-near-you',
      header: 'Find Weed Dispensaries Near You.'.toUpperCase(),
      caption: 'SEE STORES',
      image: 'homeHeader.webp',
      link: '/stores',
      variant: 'h1',
    },
    {
      name: 'deals-near-you',
      header: 'FIND DEALS NEAR YOU.',
      caption: 'SEE DEALS',
      image: 'homeHeader.webp',
      link: '/deals',
      variant: 'h2',
    },
  ];

  return (
    <div className={classes.root}>
      <Helmet>
        {/* <meta charSet="utf-8" /> */}
        <title>{`Find Weed Dispensaries & Cannabis Delivery Near You | S7DAW`}</title>
        <link rel="canonical" href={window.location.href} />
        <meta
          name="description"
          content="Seven Days A Weed (S7DAW) is your one-stop hub to discover cannabis across Canada. Order your favourite strains, along with CBD oil, edibles, concentrates, tinctures, seeds and more."
        />
        <meta
          name="ahrefs-site-verification"
          content="cd9f8fe675e508514fdccc58fd84944dc07fe93d23f09242ca80fda37f186bb5"
        ></meta>

        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
        <script type="application/ld+json">{`
                   {
                    "@context": "http://schema.org/",
                    "@type": "Organization",
                    "name": "S7DAW",
                    "url": "https://s7daw.com/",
                    "logo":
                      "http://cdn.elite-strategies.com/wp-content/uploads/2013/04/elitestrategies.png",
                    "foundingDate": "2020",
                    "sameAs": [
                      "https://www.instagram.com/s7daw.canada/",
                      "https://www.crunchbase.com/organization/s7daw"
                    ]
                  }
        `}</script>
      </Helmet>
      <CarouselManaged
        id="topBanners"
        banners={bannerData}
        type={'Home'}
        override="FIND WEED DISPENSARIES NEAR YOU"
      ></CarouselManaged>
      {/* <Typography variant="h6" component="h1" >Find Weed Dispensaries Near You </Typography> */}
      <div className={classes.content}>
        <div className={classes.contentInnerContainer}>
          {/* List Order: plt Delivery > Brands > pop delivery > mail(if ranked & in order )>  feat deals > plt stores > categories (products & ranked) */}
          {(loadingDeliveries || deliveries.length > 0) && (
            <FeaturedList
              loading={loadingDeliveries}
              index={1}
              listData={deliveries}
              title="Platinum Delivery Service"
              image="deliveries"
              type={FEATURED_TYPES.DELIVERY}
              location={location}
              viewAllPath={NAV_OPTIONS.find((option) => option.name === 'Deliveries').path}
              handleNavBarChange={(event) => handleNavBarChange(event, getNavBarIndex(FEATURED_TYPES.DELIVERY))}
            ></FeaturedList>
          )}

          {(loadingBrands || brands.length > 0) && (
            <FeaturedList
              loading={loadingBrands}
              index={2}
              listData={brands}
              title="Top Brands"
              image="popular"
              type={FEATURED_TYPES.BRAND}
              viewAllPath={NAV_OPTIONS.find((option) => option.name === 'Brands').path}
              handleNavBarChange={() => {}}
            ></FeaturedList>
          )}
          {(loadingRecommended || recommended.length > 0) && (
            <FeaturedList
              loading={loadingRecommended}
              index={3}
              listData={recommended}
              title={CITIES_USING_TOP_PICKS.includes(currCity) ? CITIES_USING_TOP_PICKS[0] : 'Most Popular deliveries'}
              image="deliveries"
              type={FEATURED_TYPES.STORE}
              location={location}
              viewAllPath={NAV_OPTIONS.find((option) => option.name === 'Deliveries').path}
              handleNavBarChange={(event) => handleNavBarChange(event, getNavBarIndex(FEATURED_TYPES.STORE))}
            ></FeaturedList>
          )}
          {(loadingNationWideDeliveries || nationWideDeliveries.length > 0) && (
            <FeaturedList
              loading={loadingNationWideDeliveries}
              index={4}
              listData={nationWideDeliveries}
              title="Mail-Outs"
              image="MailOuts"
              location={location}
              type={FEATURED_TYPES.MAILOUTS}
              viewAllPath={NAV_OPTIONS.find((option) => option.name === 'MailOuts').path}
              handleNavBarChange={(event) => handleNavBarChange(event, getNavBarIndex(FEATURED_TYPES.STORE))}
            ></FeaturedList>
          )}

          {(loadingDeals || deals.length > 0) && (
            <FeaturedList
              loading={loadingDeals}
              index={5}
              listData={deals}
              title="Get The Latest Weed Deals"
              image="deals"
              location={location}
              type={FEATURED_TYPES.DEAL}
              viewAllPath={NAV_OPTIONS.find((option) => option.name === 'Deals').path}
              handleNavBarChange={(event) => handleNavBarChange(event, getNavBarIndex(FEATURED_TYPES.DEAL))}
            ></FeaturedList>
          )}

          {(loadingStores || stores.length > 0) && (
            <FeaturedList
              loading={loadingStores}
              index={6}
              listData={stores}
              title={'Platinum Storefronts'}
              image="storefronts"
              type={FEATURED_TYPES.STORE}
              location={location}
              viewAllPath={NAV_OPTIONS.find((option) => option.name === 'Storefronts').path}
              handleNavBarChange={(event) => handleNavBarChange(event, getNavBarIndex(FEATURED_TYPES.STORE))}
            ></FeaturedList>
          )}
          {(loadingClinics || platinumClinics.length > 0) && (
            <FeaturedList
              loading={loadingStores}
              index={7}
              listData={platinumClinics}
              title="Platinum Clinics"
              image="clinics"
              type={FEATURED_TYPES.CLINIC}
              // location={location}
              viewAllPath={NAV_OPTIONS.find((option) => option.name === 'Clinics').path}
              handleNavBarChange={(event) => handleNavBarChange(event, getNavBarIndex(FEATURED_TYPES.STORE))}
            ></FeaturedList>
          )}
          {(loadingProds || flower.length > 0) && (
            <FeaturedList
              loading={loadingProds}
              index={7}
              listData={flower}
              title="Flowers"
              image="flowers"
              type={FEATURED_TYPES.FEATUREDPRODUCT}
              viewAllPath={NAV_OPTIONS.find((option) => option.name === 'Brands').path}
              handleNavBarChange={() => {}}
            />
          )}
          {(loadingProds || edibles.length > 0) && (
            <FeaturedList
              loading={loadingProds}
              index={8}
              listData={edibles}
              title="Edibles"
              image="edibles"
              type={FEATURED_TYPES.FEATUREDPRODUCT}
              viewAllPath={NAV_OPTIONS.find((option) => option.name === 'Brands').path}
              handleNavBarChange={() => {}}
            />
          )}
          {(loadingProds || concentrates.length > 0) && (
            <FeaturedList
              loading={loadingProds}
              index={9}
              listData={concentrates}
              title="Concentrates"
              image="concentrates"
              type={FEATURED_TYPES.FEATUREDPRODUCT}
              viewAllPath={NAV_OPTIONS.find((option) => option.name === 'Brands').path}
              handleNavBarChange={() => {}}
            />
          )}
          {(loadingProds || cbd.length > 0) && (
            <FeaturedList
              loading={loadingProds}
              index={10}
              listData={cbd}
              title="CBD"
              image="cbd"
              type={FEATURED_TYPES.FEATUREDPRODUCT}
              viewAllPath={NAV_OPTIONS.find((option) => option.name === 'Brands').path}
              handleNavBarChange={() => {}}
            />
          )}
          {(loadingProds || vapepens.length > 0) && (
            <FeaturedList
              loading={loadingProds}
              index={11}
              listData={vapepens}
              title="Vape Pens"
              image="vape pens"
              type={FEATURED_TYPES.FEATUREDPRODUCT}
              viewAllPath={NAV_OPTIONS.find((option) => option.name === 'Brands').path}
              handleNavBarChange={() => {}}
            />
          )}
          {!loadingStores &&
            !loadingDeliveries &&
            !loadingDeals &&
            !loadingProds &&
            !loadingBrands &&
            stores.length === 0 &&
            nationWideDeliveries.length === 0 &&
            platinumClinics.length === 0 &&
            deliveries.length === 0 &&
            deals.length === 0 &&
            products.length === 0 &&
            brands.length === 0 && (
              <Typography>
                At this time we are working to bring on partnerships with your local retailers to service this area.
                Please check back later.
              </Typography>
            )}
        </div>
      </div>
    </div>
  );
}

export default LandingPageView;
