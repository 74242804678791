import CITIES_BY_PROVINCE from './locations/CITIES_BY_PROVINCE';

const sortOptions = [
  {
    name: 'Default Sort',
    field: 'none',
    direction: 'none',
  },
  {
    name: 'Lowest Price',
    field: 'price',
    direction: 'asc',
  },
  {
    name: 'Highest Price',
    field: 'price',
    direction: 'desc',
  },
  {
    name: 'Name: A-Z',
    field: 'name',
    direction: 'asc',
  },
  {
    name: 'Name: Z-A',
    field: 'name',
    direction: 'desc',
  },
  {
    name: 'Latest',
    field: 'latest',
    direction: 'asc',
  },
  {
    name: 'Most Popular',
    field: 'popular',
    direction: 'asc',
  },
];

const MOCK_CUSTOMIZED_PRODUCT_DATA = {
  name: `Pink Gas Mask⛽️${'\uD83E\uDD2A(AstroPink)'} Amazing Deal!`,
  flags: ['Limited'],
  description:
    'Pink Gas Mask is the perfect strain for all indica lovers. A cross between Bubba Kush and a Pink Kush this strain received its name for its diesel-like aroma and flavor  profile. The indica-high provides users with a relaxing and sedating high that is great for treating insomnia and pain. Pink Gas Mask boasts a higher than average THC concentration and is sure to keep you couch locked. ',
  updateDateTime: '2020-11-08T06:10:24.427585',
  genetics: 'Indica',
  timeOfUse: '',
  flavours: [],
  productSlug: 'pink-gas-mask',
  effects: [],
  uses: [],
  stores: [
    {
      storeSlug: 'mr-nice-guys',
      name: 'Mr. Nice Guys',
      address: {
        street: '999 upper wentworth',
        city: 'Hamilton',
        province: 'Ontario',
        postalCode: 'L9A4X5',
      },
      hours: {
        mondayOpen: '08:00:00',
        mondayClose: '22:00:00',
        tuesdayOpen: '08:00:00',
        tuesdayClose: '22:00:00',
        wednesdayOpen: '09:00:00',
        wednesdayClose: '22:00:00',
        thursdayOpen: '08:00:00',
        thursdayClose: '22:00:00',
        fridayOpen: '08:00:00',
        fridayClose: '22:00:00',
        saturdayOpen: '08:00:00',
        saturdayClose: '22:00:00',
        sundayOpen: '08:00:00',
        sundayClose: '22:00:00',
      },
      published: true,
      nationwide: false,
      createDateTime: '2020-10-06T15:36:43.448253',
      longitude: -79.86153329999999,
      latitude: 43.2176239,
      storeType: 'Delivery',
      contactFirstName: 'D',
      contactLastName: 'Powell',
      adminMessages: null,
      lastMenuUpdate: '2020-11-10T14:53:26.984169',
      localDelivery: true,
      mailOutPopularRank: null,
      mailOutLatestRank: null,
      avgRating: 5.0,
      numOfReviews: '2',
      storeImage:
        'https://sdaw-qa-bucket.s3.ca-central-1.amazonaws.com/files/9B309CD5-B7D3-4AF4-AA90-80BC296F6994-1601998603100.jpeg',
      menuItems: 17,
      followersCount: 0,
      _links: {
        self: {
          href: 'https://qaapi.s7daw.com:8080/api/v1/stores/24241{?projection}',
          templated: true,
        },
        allDeliveryRanks: {
          href: 'https://qaapi.s7daw.com:8080/api/v1/stores/24241/allDeliveryRanks{?projection}',
          templated: true,
        },
        allStoreRanks: {
          href: 'https://qaapi.s7daw.com:8080/api/v1/stores/24241/allStoreRanks{?projection}',
          templated: true,
        },
        followers: {
          href: 'https://qaapi.s7daw.com:8080/api/v1/stores/24241/followers{?projection}',
          templated: true,
        },
        locations: {
          href: 'https://qaapi.s7daw.com:8080/api/v1/stores/24241/locations{?projection}',
          templated: true,
        },
        adminUnreadMessages: {
          href: 'https://qaapi.s7daw.com:8080/api/v1/stores/24241/adminUnreadMessages{?projection}',
          templated: true,
        },
        reviews: {
          href: 'https://qaapi.s7daw.com:8080/api/v1/stores/24241/reviews{?projection}',
          templated: true,
        },
        productCustomizations: {
          href: 'https://qaapi.s7daw.com:8080/api/v1/stores/24241/productCustomizations{?projection}',
          templated: true,
        },
        deals: {
          href: 'https://qaapi.s7daw.com:8080/api/v1/stores/24241/deals{?projection}',
          templated: true,
        },
        owner: {
          href: 'https://qaapi.s7daw.com:8080/api/v1/stores/24241/owner{?projection}',
          templated: true,
        },
        recommendedRanks: {
          href: 'https://qaapi.s7daw.com:8080/api/v1/stores/24241/recommendedRanks{?projection}',
          templated: true,
        },
        featuredStoreRanks: {
          href: 'https://qaapi.s7daw.com:8080/api/v1/stores/24241/featuredStoreRanks{?projection}',
          templated: true,
        },
        products: {
          href: 'https://qaapi.s7daw.com:8080/api/v1/stores/24241/products{?projection}',
          templated: true,
        },
        file: {
          href: 'https://qaapi.s7daw.com:8080/api/v1/stores/24241/file{?projection}',
          templated: true,
        },
        featuredDeliveryRanks: {
          href: 'https://qaapi.s7daw.com:8080/api/v1/stores/24241/featuredDeliveryRanks{?projection}',
          templated: true,
        },
        adminReadMessages: {
          href: 'https://qaapi.s7daw.com:8080/api/v1/stores/24241/adminReadMessages{?projection}',
          templated: true,
        },
      },
    },
  ],
  brand: null,
  removeFromMenu: false,
  productCustomizations: [],
  rank: null,
  cbdProduct: null,
  productPricingList: [
    {
      quantity: '1/8oz (3.5g)',
      price: 45.0,
    },
    {
      quantity: '1/4oz (7g)',
      price: 70.0,
    },
    {
      quantity: '1/2oz (14g)',
      price: 125.0,
    },
    {
      quantity: '1oz (28g)',
      price: 240.0,
    },
  ],
  thc: null,
  cbd: null,
  categories: {
    category: 'Flower',
    subcategory: '',
    subsubcategory: '',
  },
  avgRating: 0.0,
  numOfReviews: '0',
  imgURL:
    'https://sdaw-qa-bucket.s3.ca-central-1.amazonaws.com/files/6EB1BF64-646D-4AD1-96BE-290DC29BFBC0-1604250374018.jpeg',
  tags: [],
  _links: {
    self: {
      href: 'https://qaapi.s7daw.com:8080/api/v1/products/33900',
    },
    product: {
      href: 'https://qaapi.s7daw.com:8080/api/v1/products/33900{?projection}',
      templated: true,
    },
    stores: {
      href: 'https://qaapi.s7daw.com:8080/api/v1/products/33900/stores{?projection}',
      templated: true,
    },
    files: {
      href: 'https://qaapi.s7daw.com:8080/api/v1/products/33900/files{?projection}',
      templated: true,
    },
    reviews: {
      href: 'https://qaapi.s7daw.com:8080/api/v1/products/33900/reviews{?projection}',
      templated: true,
    },
    brand: {
      href: 'https://qaapi.s7daw.com:8080/api/v1/products/33900/brand{?projection}',
      templated: true,
    },
    productCustomizations: {
      href: 'https://qaapi.s7daw.com:8080/api/v1/products/33900/productCustomizations{?projection}',
      templated: true,
    },
  },
};

const MOCK_DEAL_DATA = {
  createDateTime: '2020-09-10T18:40:50.617216',
  updateDateTime: '2020-09-18T05:51:59.597351',
  name: 'FREE PRE-ROLL IN EVERY ORDER!',
  description: '1 FREE PRE-ROLL IN EVERY ORDER!',
  startDate: '2020-09-10T12:00:00',
  endDate: '2021-09-10T11:59:59.999',
  appliesTo: 'ALL',
  dealSlug: 'free-roll-paper',
  spendAtLeast: 0.0,
  buyAtLeastQuantity: 0.0,
  buyAtLeastGrams: 0.0,
  buy: 0,
  get: 0,
  discount: {
    type: 'Other',
    amount: null,
  },
  pushNotificationApproval: null,
  _embedded: {
    file: {
      name: 'Green Wellness Logo Icon.jpg',
      url: 'https://sdaw-qa-bucket.s3.ca-central-1.amazonaws.com/files/Green%20Wellness%20Logo%20Icon-1599763249802.jpg',
      rank: null,
      _links: {
        self: {
          href: 'https://qaapi.s7daw.com:8080/api/v1/files/17320{?projection}',
          templated: true,
        },
        product: {
          href: 'https://qaapi.s7daw.com:8080/api/v1/files/17320/product{?projection}',
          templated: true,
        },
      },
    },
    store: {
      name: 'Green Wellness',
      address: {
        street: '',
        city: 'Hamilton',
        province: 'Ontario',
        postalCode: '',
      },
      hours: {
        mondayOpen: '11:00:00',
        mondayClose: '22:00:00',
        tuesdayOpen: '11:00:00',
        tuesdayClose: '22:00:00',
        wednesdayOpen: '11:00:00',
        wednesdayClose: '22:00:00',
        thursdayOpen: '11:00:00',
        thursdayClose: '22:00:00',
        fridayOpen: '11:00:00',
        fridayClose: '22:00:00',
        saturdayOpen: '11:00:00',
        saturdayClose: '22:00:00',
        sundayOpen: '11:00:00',
        sundayClose: '22:00:00',
      },
      published: true,
      nationwide: false,
      createDateTime: '2020-09-10T18:28:44.377614',
      longitude: null,
      latitude: null,
      storeType: 'Delivery',
      contactFirstName: 'Kevin',
      contactLastName: 'Do',
      adminMessages: [
        {
          body: "We continue to deliver.  \nWe've now given you a voice.\nDid you get a bad review and just want to tell your story, or maybe you just want to thank the user for the lovely review, whatever the case. We've now released the feature for you to respond to a user's review. \n\nHope you enjoy this new feature.\n\nLet us know if you have any questions.\n\nThanks or continuing to roll with us.\n\nS7DAW",
          title: 'YOUR VOICE WILL BE HEARD!',
          createDateTime: '2020-10-30T22:19:20.008914',
          readStoresCount: 113,
          unreadStoresCount: 218,
          readBrandsCount: 5,
          unreadBrandsCount: 13,
          _links: {
            self: {
              href: 'https://qaapi.s7daw.com:8080/api/v1/adminMessages/33538{?projection}',
              templated: true,
            },
            unreadBrands: {
              href: 'https://qaapi.s7daw.com:8080/api/v1/adminMessages/33538/unreadBrands{?projection}',
              templated: true,
            },
            readBrands: {
              href: 'https://qaapi.s7daw.com:8080/api/v1/adminMessages/33538/readBrands{?projection}',
              templated: true,
            },
            unreadStores: {
              href: 'https://qaapi.s7daw.com:8080/api/v1/adminMessages/33538/unreadStores{?projection}',
              templated: true,
            },
            readStores: {
              href: 'https://qaapi.s7daw.com:8080/api/v1/adminMessages/33538/readStores{?projection}',
              templated: true,
            },
          },
        },
      ],
      lastMenuUpdate: '2020-10-20T05:59:15.321603',
      localDelivery: true,
      mailOutPopularRank: null,
      mailOutLatestRank: null,
      avgRating: 5.0,
      numOfReviews: '4',
      storeImage:
        'https://sdaw-qa-bucket.s3.ca-central-1.amazonaws.com/files/Green%20Wellness%20Logo%20business%20card-1599762522448.jpg',
      menuItems: 24,
      followersCount: 0,
      _links: {
        self: {
          href: 'https://qaapi.s7daw.com:8080/api/v1/stores/17312{?projection}',
          templated: true,
        },
        allDeliveryRanks: {
          href: 'https://qaapi.s7daw.com:8080/api/v1/stores/17312/allDeliveryRanks{?projection}',
          templated: true,
        },
        allStoreRanks: {
          href: 'https://qaapi.s7daw.com:8080/api/v1/stores/17312/allStoreRanks{?projection}',
          templated: true,
        },
        followers: {
          href: 'https://qaapi.s7daw.com:8080/api/v1/stores/17312/followers{?projection}',
          templated: true,
        },
        locations: {
          href: 'https://qaapi.s7daw.com:8080/api/v1/stores/17312/locations{?projection}',
          templated: true,
        },
        adminUnreadMessages: {
          href: 'https://qaapi.s7daw.com:8080/api/v1/stores/17312/adminUnreadMessages{?projection}',
          templated: true,
        },
        reviews: {
          href: 'https://qaapi.s7daw.com:8080/api/v1/stores/17312/reviews{?projection}',
          templated: true,
        },
        productCustomizations: {
          href: 'https://qaapi.s7daw.com:8080/api/v1/stores/17312/productCustomizations{?projection}',
          templated: true,
        },
        deals: {
          href: 'https://qaapi.s7daw.com:8080/api/v1/stores/17312/deals{?projection}',
          templated: true,
        },
        owner: {
          href: 'https://qaapi.s7daw.com:8080/api/v1/stores/17312/owner{?projection}',
          templated: true,
        },
        recommendedRanks: {
          href: 'https://qaapi.s7daw.com:8080/api/v1/stores/17312/recommendedRanks{?projection}',
          templated: true,
        },
        featuredStoreRanks: {
          href: 'https://qaapi.s7daw.com:8080/api/v1/stores/17312/featuredStoreRanks{?projection}',
          templated: true,
        },
        products: {
          href: 'https://qaapi.s7daw.com:8080/api/v1/stores/17312/products{?projection}',
          templated: true,
        },
        file: {
          href: 'https://qaapi.s7daw.com:8080/api/v1/stores/17312/file{?projection}',
          templated: true,
        },
        featuredDeliveryRanks: {
          href: 'https://qaapi.s7daw.com:8080/api/v1/stores/17312/featuredDeliveryRanks{?projection}',
          templated: true,
        },
        adminReadMessages: {
          href: 'https://qaapi.s7daw.com:8080/api/v1/stores/17312/adminReadMessages{?projection}',
          templated: true,
        },
      },
    },
    featuredDealRanks: [
      {
        location: {
          createDateTime: '2020-04-17T04:49:30.352053',
          updateDateTime: '2020-04-17T04:49:30.352056',
          province: 'Ontario',
          city: 'Scarborough',
        },
        deal: {
          name: 'FREE PRE-ROLL IN EVERY ORDER!',
          description: '1 FREE PRE-ROLL IN EVERY ORDER!',
          createDateTime: '2020-09-10T18:40:50.617216',
          storeType: 'Delivery',
          buy: 0,
          get: 0,
          discount: {
            type: 'Other',
            amount: null,
          },
          startDate: '2020-09-10T12:00:00',
          endDate: '2021-09-10T11:59:59.999',
          appliesTo: 'ALL',
          storeAddress: {
            street: '',
            city: 'Hamilton',
            province: 'Ontario',
            postalCode: '',
          },
          storeName: 'Green Wellness',
          avgRating: 5.0,
          numOfReviews: '4',
          dealImage:
            'https://sdaw-qa-bucket.s3.ca-central-1.amazonaws.com/files/Green%20Wellness%20Logo%20Icon-1599763249802.jpg',
          storeImage:
            'https://sdaw-qa-bucket.s3.ca-central-1.amazonaws.com/files/Green%20Wellness%20Logo%20business%20card-1599762522448.jpg',
          _links: {
            self: {
              href: 'https://qaapi.s7daw.com:8080/api/v1/deals/17321{?projection}',
              templated: true,
            },
            file: {
              href: 'https://qaapi.s7daw.com:8080/api/v1/deals/17321/file{?projection}',
              templated: true,
            },
            allDealRanks: {
              href: 'https://qaapi.s7daw.com:8080/api/v1/deals/17321/allDealRanks{?projection}',
              templated: true,
            },
            featuredDealRanks: {
              href: 'https://qaapi.s7daw.com:8080/api/v1/deals/17321/featuredDealRanks{?projection}',
              templated: true,
            },
            store: {
              href: 'https://qaapi.s7daw.com:8080/api/v1/deals/17321/store{?projection}',
              templated: true,
            },
          },
        },
        rank: 10,
        _links: {
          self: {
            href: 'https://qaapi.s7daw.com:8080/api/v1/featuredDealRanks/22049{?projection}',
            templated: true,
          },
          location: {
            href: 'https://qaapi.s7daw.com:8080/api/v1/featuredDealRanks/22049/location{?projection}',
            templated: true,
          },
          deal: {
            href: 'https://qaapi.s7daw.com:8080/api/v1/featuredDealRanks/22049/deal{?projection}',
            templated: true,
          },
        },
      },
      {
        location: {
          createDateTime: '2020-03-09T17:43:54.884296',
          updateDateTime: '2020-03-09T17:43:54.884305',
          province: 'Ontario',
          city: 'Hamilton',
        },
        deal: {
          name: 'FREE PRE-ROLL IN EVERY ORDER!',
          description: '1 FREE PRE-ROLL IN EVERY ORDER!',
          createDateTime: '2020-09-10T18:40:50.617216',
          storeType: 'Delivery',
          buy: 0,
          get: 0,
          discount: {
            type: 'Other',
            amount: null,
          },
          startDate: '2020-09-10T12:00:00',
          endDate: '2021-09-10T11:59:59.999',
          appliesTo: 'ALL',
          storeAddress: {
            street: '',
            city: 'Hamilton',
            province: 'Ontario',
            postalCode: '',
          },
          storeName: 'Green Wellness',
          avgRating: 5.0,
          numOfReviews: '4',
          dealImage:
            'https://sdaw-qa-bucket.s3.ca-central-1.amazonaws.com/files/Green%20Wellness%20Logo%20Icon-1599763249802.jpg',
          storeImage:
            'https://sdaw-qa-bucket.s3.ca-central-1.amazonaws.com/files/Green%20Wellness%20Logo%20business%20card-1599762522448.jpg',
          _links: {
            self: {
              href: 'https://qaapi.s7daw.com:8080/api/v1/deals/17321{?projection}',
              templated: true,
            },
            file: {
              href: 'https://qaapi.s7daw.com:8080/api/v1/deals/17321/file{?projection}',
              templated: true,
            },
            allDealRanks: {
              href: 'https://qaapi.s7daw.com:8080/api/v1/deals/17321/allDealRanks{?projection}',
              templated: true,
            },
            featuredDealRanks: {
              href: 'https://qaapi.s7daw.com:8080/api/v1/deals/17321/featuredDealRanks{?projection}',
              templated: true,
            },
            store: {
              href: 'https://qaapi.s7daw.com:8080/api/v1/deals/17321/store{?projection}',
              templated: true,
            },
          },
        },
        rank: 4,
        _links: {
          self: {
            href: 'https://qaapi.s7daw.com:8080/api/v1/featuredDealRanks/35846{?projection}',
            templated: true,
          },
          location: {
            href: 'https://qaapi.s7daw.com:8080/api/v1/featuredDealRanks/35846/location{?projection}',
            templated: true,
          },
          deal: {
            href: 'https://qaapi.s7daw.com:8080/api/v1/featuredDealRanks/35846/deal{?projection}',
            templated: true,
          },
        },
      },
      {
        location: {
          createDateTime: '2020-03-09T17:43:54.884296',
          updateDateTime: '2020-03-09T17:43:54.884305',
          province: 'Ontario',
          city: 'Hamilton',
        },
        deal: {
          name: 'FREE PRE-ROLL IN EVERY ORDER!',
          description: '1 FREE PRE-ROLL IN EVERY ORDER!',
          createDateTime: '2020-09-10T18:40:50.617216',
          storeType: 'Delivery',
          buy: 0,
          get: 0,
          discount: {
            type: 'Other',
            amount: null,
          },
          startDate: '2020-09-10T12:00:00',
          endDate: '2021-09-10T11:59:59.999',
          appliesTo: 'ALL',
          storeAddress: {
            street: '',
            city: 'Hamilton',
            province: 'Ontario',
            postalCode: '',
          },
          storeName: 'Green Wellness',
          avgRating: 5.0,
          numOfReviews: '4',
          dealImage:
            'https://sdaw-qa-bucket.s3.ca-central-1.amazonaws.com/files/Green%20Wellness%20Logo%20Icon-1599763249802.jpg',
          storeImage:
            'https://sdaw-qa-bucket.s3.ca-central-1.amazonaws.com/files/Green%20Wellness%20Logo%20business%20card-1599762522448.jpg',
          _links: {
            self: {
              href: 'https://qaapi.s7daw.com:8080/api/v1/deals/17321{?projection}',
              templated: true,
            },
            file: {
              href: 'https://qaapi.s7daw.com:8080/api/v1/deals/17321/file{?projection}',
              templated: true,
            },
            allDealRanks: {
              href: 'https://qaapi.s7daw.com:8080/api/v1/deals/17321/allDealRanks{?projection}',
              templated: true,
            },
            featuredDealRanks: {
              href: 'https://qaapi.s7daw.com:8080/api/v1/deals/17321/featuredDealRanks{?projection}',
              templated: true,
            },
            store: {
              href: 'https://qaapi.s7daw.com:8080/api/v1/deals/17321/store{?projection}',
              templated: true,
            },
          },
        },
        rank: 15,
        _links: {
          self: {
            href: 'https://qaapi.s7daw.com:8080/api/v1/featuredDealRanks/25068{?projection}',
            templated: true,
          },
          location: {
            href: 'https://qaapi.s7daw.com:8080/api/v1/featuredDealRanks/25068/location{?projection}',
            templated: true,
          },
          deal: {
            href: 'https://qaapi.s7daw.com:8080/api/v1/featuredDealRanks/25068/deal{?projection}',
            templated: true,
          },
        },
      },
    ],
  },
  _links: {
    self: {
      href: 'https://qaapi.s7daw.com:8080/api/v1/deals/17321',
    },
    deal: {
      href: 'https://qaapi.s7daw.com:8080/api/v1/deals/17321{?projection}',
      templated: true,
    },
    file: {
      href: 'https://qaapi.s7daw.com:8080/api/v1/deals/17321/file{?projection}',
      templated: true,
    },
    allDealRanks: {
      href: 'https://qaapi.s7daw.com:8080/api/v1/deals/17321/allDealRanks{?projection}',
      templated: true,
    },
    featuredDealRanks: {
      href: 'https://qaapi.s7daw.com:8080/api/v1/deals/17321/featuredDealRanks{?projection}',
      templated: true,
    },
    store: {
      href: 'https://qaapi.s7daw.com:8080/api/v1/deals/17321/store{?projection}',
      templated: true,
    },
  },
};

const MOCK_BRANDS_DATA = {
  name: 'Twisted Extracts',
  address: {
    street: 'PO BOX 7100 ',
    city: 'Vancouver',
    province: 'British Columbia',
    postalCode: 'V5H3Z9',
  },
  brandSlug: 'twisted-extracts',
  hours: {
    mondayOpen: '10:00:00',
    mondayClose: '00:00:00',
    tuesdayOpen: '10:00:00',
    tuesdayClose: '00:00:00',
    wednesdayOpen: '10:00:00',
    wednesdayClose: '00:00:00',
    thursdayOpen: '10:00:00',
    thursdayClose: '00:00:00',
    fridayOpen: '10:00:00',
    fridayClose: '00:00:00',
    saturdayOpen: '10:00:00',
    saturdayClose: '00:00:00',
    sundayOpen: '10:00:00',
    sundayClose: '00:00:00',
  },
  published: true,
  createDateTime: '2020-06-17T17:53:42.899057',
  email: 'info@twistedextracts.com',
  aboutUs:
    'We make cannabis edibles to help people live better, happier lives. We take the guesswork out of cannabis consumption with our reliable and consistently dosed, fully-infused edibles, and we’re best known for our original Jelly Bomb.',
  displayMap: false,
  snapchat: '',
  facebook: 'www.facebook.com/twistedextracts/',
  instagram: '@twistedextracts_2.0',
  twitter: '',
  website: 'twistedextracts.com',
  phone: '2507922858',
  avgRating: 5.0,
  numOfReviews: '1',
  brandImage: 'https://sdaw-qa-bucket.s3.ca-central-1.amazonaws.com/files/TE_logo-1592416421962.png',
  _links: {
    self: {
      href: 'https://qaapi.s7daw.com:8080/api/v1/brands/6753',
    },
    brand: {
      href: 'https://qaapi.s7daw.com:8080/api/v1/brands/6753{?projection}',
      templated: true,
    },
    file: {
      href: 'https://qaapi.s7daw.com:8080/api/v1/brands/6753/file{?projection}',
      templated: true,
    },
    products: {
      href: 'https://qaapi.s7daw.com:8080/api/v1/brands/6753/products{?projection}',
      templated: true,
    },
    reviews: {
      href: 'https://qaapi.s7daw.com:8080/api/v1/brands/6753/reviews{?projection}',
      templated: true,
    },
    owner: {
      href: 'https://qaapi.s7daw.com:8080/api/v1/brands/6753/owner{?projection}',
      templated: true,
    },
    locations: {
      href: 'https://qaapi.s7daw.com:8080/api/v1/brands/6753/locations{?projection}',
      templated: true,
    },
    adminMessages: {
      href: 'https://qaapi.s7daw.com:8080/api/v1/brands/6753/adminMessages{?projection}',
      templated: true,
    },
  },
};

const MOCK_STOREFRONT_DATA = {
  name: 'The Hunny Pot Cannabis Co.',
  address: {
    street: '40 Centennial Pkwy North',
    city: 'Hamilton',
    province: 'Ontario',
    postalCode: 'L8E1H6',
  },
  hours: {
    mondayOpen: '10:00:00',
    mondayClose: '21:00:00',
    tuesdayOpen: '10:00:00',
    tuesdayClose: '21:00:00',
    wednesdayOpen: '10:00:00',
    wednesdayClose: '21:00:00',
    thursdayOpen: '10:00:00',
    thursdayClose: '21:00:00',
    fridayOpen: '10:00:00',
    fridayClose: '21:00:00',
    saturdayOpen: '10:00:00',
    saturdayClose: '21:00:00',
    sundayOpen: '10:00:00',
    sundayClose: '19:00:00',
  },
  paymentOptions: ['Cash'],
  published: true,
  nationwide: false,
  createDateTime: '2020-08-05T18:30:37.146431',
  email: 'hi@thehunnypot.com',
  storeSlug: 'the-hunny-pot',
  storeType: 'Storefront',
  aboutUs:
    'We are an independently owned & operated cannabis retail company, and proud to be Toronto’s very first legal cannabis store, opening our flagship location on Queen Street West April 1st, 2019. Over the last year we skipped hibernation and have been busy expanding The Hunny Pot footprint, opening 5 new locations in 2020. You can find The Hunny Pot in Hamilton, Burlington, and Toronto… for now.',
  displayMap: true,
  snapchat: '',
  facebook: '',
  instagram: '@thehunnypotcannabisco',
  twitter: '',
  website: 'thehunnypot.com',
  phone: '6475304202',
  announcement: '',
  firstCustomerSpecial: '',
  minimumAge: 19,
  avgRating: 0.0,
  numOfReviews: '0',
  storeImage: 'https://sdaw-qa-bucket.s3.ca-central-1.amazonaws.com/files/TheHunnyPot%20Logo-1596652236883.jpg',
  menuItems: 0,
  _links: {
    self: {
      href: 'https://qaapi.s7daw.com:8080/api/v1/stores/8587',
    },
    store: {
      href: 'https://qaapi.s7daw.com:8080/api/v1/stores/8587{?projection}',
      templated: true,
    },
    allDeliveryRanks: {
      href: 'https://qaapi.s7daw.com:8080/api/v1/stores/8587/allDeliveryRanks{?projection}',
      templated: true,
    },
    allStoreRanks: {
      href: 'https://qaapi.s7daw.com:8080/api/v1/stores/8587/allStoreRanks{?projection}',
      templated: true,
    },
    followers: {
      href: 'https://qaapi.s7daw.com:8080/api/v1/stores/8587/followers{?projection}',
      templated: true,
    },
    locations: {
      href: 'https://qaapi.s7daw.com:8080/api/v1/stores/8587/locations{?projection}',
      templated: true,
    },
    adminUnreadMessages: {
      href: 'https://qaapi.s7daw.com:8080/api/v1/stores/8587/adminUnreadMessages{?projection}',
      templated: true,
    },
    reviews: {
      href: 'https://qaapi.s7daw.com:8080/api/v1/stores/8587/reviews{?projection}',
      templated: true,
    },
    productCustomizations: {
      href: 'https://qaapi.s7daw.com:8080/api/v1/stores/8587/productCustomizations{?projection}',
      templated: true,
    },
    deals: {
      href: 'https://qaapi.s7daw.com:8080/api/v1/stores/8587/deals{?projection}',
      templated: true,
    },
    owner: {
      href: 'https://qaapi.s7daw.com:8080/api/v1/stores/8587/owner{?projection}',
      templated: true,
    },
    recommendedRanks: {
      href: 'https://qaapi.s7daw.com:8080/api/v1/stores/8587/recommendedRanks{?projection}',
      templated: true,
    },
    featuredStoreRanks: {
      href: 'https://qaapi.s7daw.com:8080/api/v1/stores/8587/featuredStoreRanks{?projection}',
      templated: true,
    },
    products: {
      href: 'https://qaapi.s7daw.com:8080/api/v1/stores/8587/products{?projection}',
      templated: true,
    },
    file: {
      href: 'https://qaapi.s7daw.com:8080/api/v1/stores/8587/file{?projection}',
      templated: true,
    },
    featuredDeliveryRanks: {
      href: 'https://qaapi.s7daw.com:8080/api/v1/stores/8587/featuredDeliveryRanks{?projection}',
      templated: true,
    },
    adminReadMessages: {
      href: 'https://qaapi.s7daw.com:8080/api/v1/stores/8587/adminReadMessages{?projection}',
      templated: true,
    },
  },
};

const filterTypes = {
  CATEGORY: 'CATEGORY',
  GENETICS: 'GENETICS',
  PRICE_RANGE: 'PRICE_RANGE',
  FLAGS: 'FLAGS',
  BRANDS: 'BRANDS',
  CLEAR: 'CLEAR',
};

//Price ranges will be inclusive for the lower range and exclusive for the higher range.
//Ex. "Under $25" applies to items priced >= $0 and < $25. "$25 to $50" applies to items prices >= $25 and < $50.
const priceRanges = [
  {
    name: 'All',
    low: 0,
    high: 999999,
  },
  {
    name: 'Under $25',
    low: 0,
    high: 25,
  },
  {
    name: '$25 to $50',
    low: 25,
    high: 50,
  },
  {
    name: '$50 to $100',
    low: 50,
    high: 100,
  },
  {
    name: '$100 to 200',
    low: 100,
    high: 200,
  },
  {
    name: 'Over $200',
    low: 200,
    high: 999999,
  },
];

const NONE = 'None';
const ALL_CATEGORIES = 'Show All Products';
const STORE_OPEN = 'OPEN NOW';
const STORE_CLOSED = 'CLOSED UNTIL';
const PRODUCT_FLAGS = {
  NEW: 'New',
  OUTOFSTOCK: 'Out of Stock',
  SPECIAL: 'Special',
  LIMITED: 'Limited',
};

const ABOUT_CONTENT =
  'Seven Days A Weed (S7DAW) is your one-stop hub to discover cannabis across Canada. We connect recreational and medical cannabis users with the best weed dispensaries, weed deals, brands, products, and information.';

const NAV_OPTIONS = [
  { name: 'Home', path: '/', index: 0 },
  { name: 'Deliveries', path: '/deliveries', index: 1 },
  { name: 'MailOuts', path: '/mailouts', index: 2 },
  { name: 'Brands', path: '/brands', index: 3 },
  { name: 'Storefronts', path: '/stores', index: 4 },
  { name: 'Deals', path: '/deals', index: 5 },
  { name: 'Clinics', path: '/clinics', index: 6 },
  { name: 'Maps', path: '/maps', index: 7 },
];

const REVIEW_POLICY = `By clicking 'Submit' or 'Post Review', you acknowledge that your review is not compromised of/does not contain:

• Spam and fake reviews.

• Multiple negative reviews from the same person or by a fake user we identify coming from the same IP address location.

• Inappropriate content, profanity and racial terms.

• Fraudulent reviews from competitors.

• Leaving reviews for businesses you didn’t interact with.

• Reviews that aren’t relevant to an actual experience.

• A review by someone who has a direct or indirect financial interest in the product.

• Multiple negative reviews for the same product from one customer.

• A review in exchange for monetary reward.

• A negative review from a seller on a competitor's service.

If S7DAW can determine upon an investigation that your review meets any of the above criteria, we hold the right to remove the review.`;

const FEATURED_TYPES = {
  STORE: 'Storefronts',
  DELIVERY: 'Deliveries',
  DEAL: 'Deals',
  BRAND: 'Brands',
  MAILOUTS: 'MailOuts',
  BRANDPRODUCT: 'Brand Products',
  FEATUREDPRODUCT: 'Featured Products',
  CLINIC: 'Clinics',
};

const STORE_TYPES = ['Storefront', 'Delivery', 'Clinic'];

const PAGES = {
  HOME: 'HOME',
  STOREFRONTS: 'STOREFRONTS',
  DELIVERIES: 'DELIVERIES',
  DEALS: 'DEALS',
  STORE_DETAILS: 'STORE_DETAILS',
  DEAL_DETAILS: 'DEAL_DETAILS',
};

const NAV_REDUCER_ACTIONS = {
  CHANGE_PAGE: 'CHANGE_PAGE',
};

const DAYS_OF_THE_WEEK = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const HOURS_OF_THE_DAY = [
  'Closed',
  '24 Hours',
  '12:00 am',
  '12:30 am',
  '1:00 am',
  '1:30 am',
  '2:00 am',
  '2:30 am',
  '3:00 am',
  '3:30 am',
  '4:00 am',
  '4:30 am',
  '5:00 am',
  '5:30 am',
  '6:00 am',
  '6:30 am',
  '7:00 am',
  '7:30 am',
  '8:00 am',
  '8:30 am',
  '9:00 am',
  '9:30 am',
  '10:00 am',
  '10:30 am',
  '11:00 am',
  '11:30 am',
  '12:00 pm',
  '12:30 pm',
  '1:00 pm',
  '1:30 pm',
  '2:00 pm',
  '2:30 pm',
  '3:00 pm',
  '3:30 pm',
  '4:00 pm',
  '4:30 pm',
  '5:00 pm',
  '5:30 pm',
  '6:00 pm',
  '6:30 pm',
  '7:00 pm',
  '7:30 pm',
  '8:00 pm',
  '8:30 pm',
  '9:00 pm',
  '9:30 pm',
  '10:00 pm',
  '10:30 pm',
  '11:00 pm',
  '11:30 pm',
];

const EMPTY_STORE_HOURS = {
  mondayOpen: null,
  mondayClose: null,
  tuesdayOpen: null,
  tuesdayClose: null,
  wednesdayOpen: null,
  wednesdayClose: null,
  thursdayOpen: null,
  thursdayClose: null,
  fridayOpen: null,
  fridayClose: null,
  saturdayOpen: null,
  saturdayClose: null,
  sundayOpen: null,
  sundayClose: null,
};

const PAYMENT_OPTIONS = ['Cash', 'Debit', 'Credit', 'E-Transfer'];

const LICENSE_TYPES = ['None', 'Type 1', 'Type 2', 'Type 3'];

const PROVINCES = [
  'Alberta',
  'British Columbia',
  'Manitoba',
  'New Brunswick',
  'Newfoundland and Labrador',
  'Northwest Territories',
  'Nova Scotia',
  'Nunavut',
  'Ontario',
  'Prince Edward Island',
  'Quebec',
  'Saskatchewan',
  'Yukon',
];

const PLANT_TYPES = ['Indica', 'Hybrid', 'Sativa'];

const TIME_OF_USE = ['Day', 'Dusk', 'Evening', 'Morning'];

const FLAVOURS = ['Fruity', 'Earthy', 'Sweet', 'Sour', 'Skunk', 'Citrus', 'Woody', 'Pine', 'Gas'];

const COMMON_EFFECTS = [
  'Euphoria',
  'Happy',
  'Relaxed',
  'Focused',
  'Energy',
  'Sedated',
  'Creativity',
  'Hungry',
  'Cotton Mouth',
  'Paranoia',
  'Sleepy',
  'Body High',
];

const COMMON_USAGE = [
  'Stress',
  'Pain',
  'Depression',
  'Loss of Appetite',
  'ADHD',
  'Anxiety',
  'Migraines',
  'Arthritis',
  'Nausea',
  'Insomnia',
];

const FLAGS = ['New', 'Out of Stock', 'Special', 'Limited'];

const CATEGORIES = [
  {
    Concentrates: {
      Budder: {},
      Crumble: {},
      Distillate: {
        Jar: {},
        Syringe: {},
      },
      'Flower Rosin': {},
      Hash: {},
      'Hash Rosin': {},
      Kief: {},
      'Live Resin': {},
      Sauce: {},
      Shatter: {},
      Taffy: {},
    },
  },
  {
    Cultivation: {
      Clone: {},
      'Fresh Frozen': {},
      Seeds: {},
      Trim: {},
      'Whole Plant': {},
    },
  },
  {
    CBD: {
      Edibles: {
        'Baked Goods': {
          Brownies: {},
          Cookies: {},
          Treats: {},
        },
        Beverages: {
          Drinks: {},
          Powder: {},
          Syrup: {},
        },
        Candy: {
          Chocolates: {},
          Gummies: {},
          'Mints & Gum': {},
        },
        Capsules: {},
        'Cooking Ingredients': {
          Butter: {},
          Condiments: {},
        },
        'Edible Oils': {
          'Oral Sprays': {},
          Tinctures: {},
        },
        Snacks: {
          'Ice Cream': {},
          Cereal: {},
        },
      },
      Topicals: {
        'Balms & Salves': {},
        Bath: {
          Bombs: {},
          'Soaking Salts': {},
        },
        Lotions: {},
        'Lubricants & Oils': {},
        Patches: {},
        Sprays: {},
      },
      Flower: {},
    },
  },
  {
    Edibles: {
      'Baked Goods': {
        Brownies: {},
        Cookies: {},
        Treats: {},
      },
      Beverages: {
        Drinks: {},
        Powder: {},
        Syrup: {},
      },
      Candy: {
        Chocolates: {},
        Gummies: {},
        'Mints & Gum': {},
      },
      Capsules: {},
      'Cooking Ingredients': {
        Butter: {},
        Condiments: {},
      },
      'Edible Oils': {
        'Oral Sprays': {},
        Tinctures: {},
      },
      Snacks: {
        'Ice Cream': {},
        Cereal: {},
      },
    },
  },
  {
    Flower: {
      'Infused Flower': {},
      'Moon Rocks': {},
      'Pre Rolls': {
        'Blunt Wraps': {},
        'Infused Pre Rolls': {},
      },
      Shake: {},
    },
  },
  {
    Gear: {
      Accessories: {
        'Blunt Wraps': {},
        'Rolling Papers': {},
        Stickers: {},
      },
      Apparel: {
        Hats: {},
        Hoodies: {},
        Shirts: {},
      },
      Bongs: {
        Adapters: {},
        'Ash Catchers': {},
        Bowls: {},
        Bubblers: {},
        Downstems: {},
      },
      'Dab Rigs': {
        'Banger & Nails': {},
        'Carb Cap': {},
        'Dab Mats': {},
        'Dab Tools': {},
        'E-nails': {},
        Torches: {},
      },
      Grinders: {},
      Pipes: {},
      Storage: {},
      Vaporizers: {},
    },
  },
  {
    Topicals: {
      'Balms & Salves': {},
      Bath: {
        Bombs: {},
        'Soaking Salts': {},
      },
      Lotions: {},
      'Lubricants & Oils': {},
      Patches: {},
      Sprays: {},
    },
  },
  {
    'Vape Pens': {
      Batteries: {
        'Pull To Start': {},
        'Push Button': {},
      },
      Cartridge: {},
      Disposable: {},
      Pods: {},
    },
  },
  {
    Other: {},
  },
];

const DISCOUNT_TYPES = ['Percent (%) Off', 'Amount ($) Off', 'Buy X Get Y', 'Other'];

const STATUS = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  DUPLICATE: 'DUPLICATE',
  BANNER_ATTACHED: 'BANNER_ATTACHED',
  UPDATE_SUCCESS: 'UPDATE_SUCCESS',
};

const MOCK_DELIVERIES_DATA = {
  name: 'Rocky Mountain Quads',
  address: {
    street: '',
    city: 'Hamilton',
    province: 'Ontario',
    postalCode: '',
  },
  hours: {
    mondayOpen: '10:00:00',
    mondayClose: '21:00:00',
    tuesdayOpen: '10:00:00',
    tuesdayClose: '21:00:00',
    wednesdayOpen: '10:00:00',
    wednesdayClose: '21:00:00',
    thursdayOpen: '10:00:00',
    thursdayClose: '21:00:00',
    fridayOpen: '10:00:00',
    fridayClose: '21:00:00',
    saturdayOpen: '10:00:00',
    saturdayClose: '21:00:00',
    sundayOpen: null,
    sundayClose: null,
  },
  paymentOptions: ['Cash'],
  published: true,
  nationwide: true,
  createDateTime: '2020-05-09T00:55:13.206227',
  email: 'Rockymountainquads@gmail.com',
  storeType: 'Delivery',
  storeSlug: 'rocky-mountain',
  aboutUs:
    'Here at RMQ, we pride ourselves in providing a quick reliable service that provides an extremely high qaulity product. While we are built around offering the absolute best premium craft cannabis, we understand that many of our products may be stronger or pricier than one may desire. For that reason we have decided to offer plenty of alternative options to suit your needs.\n\nWe understand the question; why is our craft cannabis so darned expensive when compared to the neighbouring competition? The answer is quite simple really. "Cheap labour ain\'t good, and good labour ain\'t cheap!" All of OUR CRAFT cannabis is flowered for 9-12 weeks, manicured by hand, as well as a minimum 4 week cure.\n\nAt the end of the day you get what you pay for.\n\n \n\nWE STAND BEHIND ALL OF OUR PRODUCTS.\n\n \n\nCUSTOMER SATISFACTION AND A 5 STAR RATING IS OUR PRIORITY.\n\n \n\nBEST BANG FOR YOUR BUCK ACROSS THE BOARD GAURENTEED!! CHECK OUR REVIEWS!!!!!!!\n\n \n\nWE STAND BEHIND ALL OF OUR PRODUCTS, NO IF ANDS OR BUTS!\n\n \n\nCONSISTINTCY AND LONGEVITY; WE VALUE OUR RELATIONSHIPS!\n\n\n\nDUE TO COVID 19 WE ARE NO LONGER DOING EXCHANGES! WE SILL STAND BEHIND ALL OUR PRODUCTS AND WILL TRY OUR ABSOLUTE BEST TO RECIFY ANY ISSUE.\n\n \n\nA $10 donation will be applied to ALL Orders needing to be swapped. This also applies to Donators of downtown Hamilton.\n\nID WILL BE CHECKED UPON ARRIVAL. 19\n\n \n\nWARNING; MANY OF OUR PRODUCTS CONTAIN A HIGHER LEVEL OF THC %. PLEASE DO NOT OPERATE HEAVY MACHINERY OR MOTOR VEHICLES DURING OR AFTER CONSUMPTION.\n\n \n\nTHANK YOU FOR STOPPING BY!\n\n \n\nCHEERS\n\n \n\nROCKY MOUNTAIN QUADS',
  displayMap: true,
  snapchat: null,
  facebook: '',
  instagram: '',
  twitter: '',
  website: 'https://rockymountainquads.com/',
  phone: '2892440874',
  announcement:
    '\uD83D\uDD25\uD83D\uDD25\uD83D\uDD25SPECIALS ON NOW!!!⛽️⛽️⛽️\n\n\uD83D\uDE4F FREE DELIVERY ALL MONTH\n\n\uD83D\uDCA5FREE 250mg assorted gummies with each order.\n\n\uD83D\uDD25GUMMIES DEALS DO NOT STACK! Limit 1 free pack per order\n\n \n\n\uD83D\uDCA8\uD83D\uDCA8\uD83D\uDCA8HOW TO ORDER⛽️⛽️⛽️\n\n \n\nTEXT OR EMAIL\n\n1) Tell us what flower you want to donate towards\n\n2) Provide \n\n-Name\n\n-Delivery address\n\n-Best contact number\n\n (We will call you 10-15min prior to arrival and again on arrival.)\n\n\n\n \n\nPlease DO NOT call the phone number unless you need to speak with customer service. Text messages and Emails helps to ensure accuracy and prevent miscommunications.\n\nDue to high customer volume, please allow 5-15min for replies. If your order is placed before closing hours, your order will arrive same day. Thank you for your understanding\n\nALL DONATIONS ARE COD\n\n \n\nWe are very DISCREET, your privacy is very important to us.\n\n \n\n60 Dollar Minimum Donation BEFORE DELIVERY Required !!\n\n \n\nSAID DONATION INDICATES GIFT RECEIVED.\n\n \n\nDOWNTOWN HAMILTON 5 DOLLAR DELIVERY\n\n \n\nUP THE MOUNTAIN, EAST END, WEST END HAMILTON 10 DOLLAR DELIVERY. \n\n \n\nBURLINGTON 10 DOLLAR DELIVERY DONATION.... \n\n \n\nANCASTER, GRIMSBY, LINCOLN, DUNDAS, WATERDOWN 15 DOLLAR DELIVERY DONATION...... \n\n \n\n \n\nFREE DELIVERY ON ALL ORDERS OVER $125\n\n \n\nSURROUNDING AREAS OUTSIDE OF HAMILTON WILL BE SUBJECT TO AN ADDITIONAL DELIVERY FEE\n\n \n\nDUE TO COVID 19 WE ARE NO LONGER DOING EXCHANGES! WE SILL STAND BEHIND ALL OUR PRODUCTS AND WILL TRY OUR ABSOLUTE BEST TO RECIFY ANY ISSUE.\n\n \n\nID WILL BE CHECKED UPON ARRIVAL. 19',
  firstCustomerSpecial: '',
  minimumAge: 19,
  avgRating: 5.0,
  numOfReviews: '4',
  storeImage:
    'https://sdaw-qa-bucket.s3.ca-central-1.amazonaws.com/files/1580168305-5075B194-024B-4348-9319-A13B60E37F79-1588985712635.jpeg',
  menuItems: 86,
  _links: {
    self: {
      href: 'https://qaapi.s7daw.com:8080/api/v1/stores/3583',
    },
    store: {
      href: 'https://qaapi.s7daw.com:8080/api/v1/stores/3583{?projection}',
      templated: true,
    },
    allDeliveryRanks: {
      href: 'https://qaapi.s7daw.com:8080/api/v1/stores/3583/allDeliveryRanks{?projection}',
      templated: true,
    },
    allStoreRanks: {
      href: 'https://qaapi.s7daw.com:8080/api/v1/stores/3583/allStoreRanks{?projection}',
      templated: true,
    },
    followers: {
      href: 'https://qaapi.s7daw.com:8080/api/v1/stores/3583/followers{?projection}',
      templated: true,
    },
    locations: {
      href: 'https://qaapi.s7daw.com:8080/api/v1/stores/3583/locations{?projection}',
      templated: true,
    },
    adminUnreadMessages: {
      href: 'https://qaapi.s7daw.com:8080/api/v1/stores/3583/adminUnreadMessages{?projection}',
      templated: true,
    },
    reviews: {
      href: 'https://qaapi.s7daw.com:8080/api/v1/stores/3583/reviews{?projection}',
      templated: true,
    },
    productCustomizations: {
      href: 'https://qaapi.s7daw.com:8080/api/v1/stores/3583/productCustomizations{?projection}',
      templated: true,
    },
    deals: {
      href: 'https://qaapi.s7daw.com:8080/api/v1/stores/3583/deals{?projection}',
      templated: true,
    },
    owner: {
      href: 'https://qaapi.s7daw.com:8080/api/v1/stores/3583/owner{?projection}',
      templated: true,
    },
    recommendedRanks: {
      href: 'https://qaapi.s7daw.com:8080/api/v1/stores/3583/recommendedRanks{?projection}',
      templated: true,
    },
    featuredStoreRanks: {
      href: 'https://qaapi.s7daw.com:8080/api/v1/stores/3583/featuredStoreRanks{?projection}',
      templated: true,
    },
    products: {
      href: 'https://qaapi.s7daw.com:8080/api/v1/stores/3583/products{?projection}',
      templated: true,
    },
    file: {
      href: 'https://qaapi.s7daw.com:8080/api/v1/stores/3583/file{?projection}',
      templated: true,
    },
    featuredDeliveryRanks: {
      href: 'https://qaapi.s7daw.com:8080/api/v1/stores/3583/featuredDeliveryRanks{?projection}',
      templated: true,
    },
    adminReadMessages: {
      href: 'https://qaapi.s7daw.com:8080/api/v1/stores/3583/adminReadMessages{?projection}',
      templated: true,
    },
  },
};

const ROLES = {
  ROLE_SUPER_ADMIN: 'ROLE_SUPER_ADMIN',
  ROLE_ADMIN: 'ROLE_ADMIN',
  ROLE_STORE_ADMIN: 'ROLE_STORE_ADMIN',
  ROLE_BRAND_ADMIN: 'ROLE_BRAND_ADMIN',
  ROLE_USER: 'ROLE_USER',
};

/* CITIES org format
{
  "Alberta":{
    12345: "Airdrie",
    12346: "Athabasca",
  },
  "British Columbia":{
    12347: "Abbotsford",
    12348: "Armstrong",
  },
  ...
}
*/

const createCityList = (province, cities) => {
  //Use CITIES_BY_PROVINCE to build lists for each province
  // {Alberta: {1234: 'Calgary"}, "British Columbia":{3214: "Vancouver"}...}
  //Any new Cities are to be added to the respective provice file with city center coordinates.
  const cityListByProvince = {};

  Object.entries(CITIES_BY_PROVINCE).forEach((entry, index) => {
    //Replace _ with spaces
    const provinceName = entry[0]; //Province name Snake Case
    const citiesList = Object.keys(entry[1]); //Object keys are the city names
    const parsedProvince = provinceName.replace(/_/g, ' ');
    //Add the province if it doesnt exist
    if (!cityListByProvince[`${parsedProvince}`]) cityListByProvince[`${parsedProvince}`] = {};

    citiesList.forEach((city, index) => {
      cityListByProvince[`${parsedProvince}`][`${provinceName}_${index}`] = city;
    });
  });
  return cityListByProvince;
};
const CITIES = createCityList();

const SCREEN_STATES = {
  STORE_DETAILS: 'STORE_DETAILS',
  CLINIC_DETAILS: 'CLINIC_DETAILS',
  EDIT_STORE: 'EDIT_STORE',
  BRAND_DETAILS: 'BRAND_DETAILS',
  EDIT_BRAND: 'EDIT_BRAND',
  PRODUCT_DETAILS: 'PRODUCT_DETAILS',
  EDIT_PRODUCT: 'EDIT_PRODUCT',
  DEAL_DETAILS: 'DEAL_DETAILS',
  EDIT_DEAL: 'EDIT_DEAL',
};

const QUANTITY_TYPES = {
  Standard: 'Standard',
  Custom: 'Custom',
};

const STANDARD_QUANTITIES = ['1g', '1/8oz (3.5g)', '1/4oz (7g)', '1/2oz (14g)', '1oz (28g)'];

const HEADER_TYPE = {
  BRAND: 'BRAND',
  MAILOUT: 'MAILOUT',
};

const CITIES_USING_TOP_PICKS = [
  "This Month's Top Picks", // Verbage shown
  //Cities that list feature lists at Top 10, rather than Platinum or Most
  // Start with array of city names if overlap occurs b/c provinces then move to an object
  /* Duplicates data should reference existing cities?, but that should eventually be phased out... for single source. */
  'London',
];

export {
  sortOptions,
  filterTypes,
  priceRanges,
  NONE,
  ALL_CATEGORIES,
  STORE_OPEN,
  STORE_CLOSED,
  PRODUCT_FLAGS,
  NAV_OPTIONS,
  ABOUT_CONTENT,
  FEATURED_TYPES,
  STORE_TYPES,
  PAGES,
  NAV_REDUCER_ACTIONS,
  DAYS_OF_THE_WEEK,
  HOURS_OF_THE_DAY,
  EMPTY_STORE_HOURS,
  PAYMENT_OPTIONS,
  LICENSE_TYPES,
  PROVINCES,
  PLANT_TYPES,
  TIME_OF_USE,
  FLAVOURS,
  COMMON_EFFECTS,
  COMMON_USAGE,
  FLAGS,
  CATEGORIES,
  DISCOUNT_TYPES,
  STATUS,
  ROLES,
  CITIES,
  SCREEN_STATES,
  QUANTITY_TYPES,
  STANDARD_QUANTITIES,
  HEADER_TYPE,
  REVIEW_POLICY,
  MOCK_CUSTOMIZED_PRODUCT_DATA,
  MOCK_DELIVERIES_DATA,
  MOCK_STOREFRONT_DATA,
  MOCK_DEAL_DATA,
  MOCK_BRANDS_DATA,
  CITIES_USING_TOP_PICKS,
};
