/* 
Component that emulates a short url
Interprets the ID as a store ID then redirects to that page
Depending on the search key, it will redirect to the store, brand, product page, or deal page
Search keys:
s = stores
b = brands
p = products
d = deals
*/

/* 
192.168.1.65:3000/q?s=19
*/

import React, { useEffect, useState } from 'react';
// eslint-disable-next-line no-unused-vars
import { BrowserRouter as Router, Redirect } from 'react-router-dom';
//To use Redirect MUST import the BrowserRouter - not sure why...

import api from '../utils/api';
import { Helmet } from 'react-helmet';
import { calcDaysFromToday } from '../utils/helpers';

export default function ShortURLRedirector({ props }) {
  const [urlPath, setUrlPath] = useState(null);
  const [queryComplete, setQueryComplete] = useState(false);

  const queryString = props.location.search;
  const searchParams = new URLSearchParams(queryString);
  const firstQuery = searchParams.entries().next().value;

  //Uses optional chaining to avoid error if firstQuery is undefined.  Will redirect to home page if firstQuery is undefined after useEffect
  const entityKey = firstQuery?.[0];
  const entityID = firstQuery?.[1];

  let apiCall = null;
  let entityType = '';
  switch (entityKey) {
    case 's':
      apiCall = api.getStore(entityID);
      break;
    case 'b':
      entityType = 'brands';
      apiCall = api.getBrand(entityID);
      break;
    case 'p':
      entityType = 'products';
      apiCall = api.getProduct(entityID);
      break;
    case 'd':
      entityType = 'deals';
      apiCall = api.getDeal(entityID);
      break;
    default:
      console.log('Invalid search key');
      break;
  }
  //Get entity info from API
  //If search key is invalid, redirect to home page
  //If search key is valid, redirect to entity page
  //If entity is not live, redirect to home page

  useEffect(() => {
    const fetchData = async () => {
      try {
        const rawRes = await apiCall;
        const result = rawRes.data;
        let urlToSet = '';

        if (entityKey === 's') {
          //Stores Entity - Clinics / Storefronts / Deliveries
          const isClinic = result.storeType === 'Clinic';
          const isPublished = result.published;
          const { province, city } = result.address;

          if (isClinic) {
            urlToSet = `/clinics/${entityID}/${result.slug}`;
          } else {
            const storeType = result.storeType === 'Storefront' ? 'stores' : 'deliveries';
            urlToSet = `/${storeType}/${province.toLowerCase()}/${city.toLowerCase()}/${entityID}/${result.slug}`;
          }

          if (isPublished) {
            return setUrlPath(urlToSet);
          }
        }

        if (entityType === 'deals') {
          //If the store is not live, the deal will not be live
          const { province, city } = result._embedded.store.address;
          urlToSet = `/deals/${province.toLowerCase()}/${city.toLowerCase()}/${entityID}/${result.slug}`;

          const isStorePublished = result._embedded.store.published;
          const isUpcoming = calcDaysFromToday(result.startDate) > 0;
          const isExpired = calcDaysFromToday(result.endDate) < 0;
          const isActiveDeal = !isUpcoming && !isExpired;

          if (isStorePublished && isActiveDeal) {
            return setUrlPath(urlToSet); //If all deals are permitted (expired and upcoming), remove if condition
            /* Deals view doesn't show expired or upcoming, 
            but the indv page can be viewed with direct links
            With if condition, only active deals can be viewed
            */
          }
        }
      } catch (error) {
        console.log('error', error); // Failed to fetch causes redirect to home page
      } finally {
        setQueryComplete(true);
      }
    };
    apiCall && fetchData();

    return () => {};
  }, [entityID, apiCall, entityType, entityKey]);

  const invalidInputs = firstQuery === undefined || apiCall === null; //No Query or Invalid Query
  if (invalidInputs) {
    return <Redirect to="/" />;
  }
  // For location specific use the primary location?
  const hasURL = !!urlPath;
  //=============================== MODES ========================================
  const validURL = queryComplete && hasURL;
  const invalidURL = queryComplete && !urlPath;
  const loading = !queryComplete;

  const shortURL404 = (
    <div>
      <h2>Uh oh, that URL doesn't seem to exist</h2>
      <p>It may have existed at some point, but might have been removed.</p>
      <a href="/">Return to home page</a>
    </div>
  );

  return (
    <>
      <Helmet>
        <title>S7DAW - Short URL...</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <div style={{ display: 'grid', placeContent: 'center', height: 400, textAlign: 'center' }}>
        {validURL && <Redirect to={urlPath} />}
        {invalidURL && shortURL404}
        {loading && <div>Loading...</div>}
      </div>
    </>
  );
}
