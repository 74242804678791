import React, { useState, useEffect, Fragment } from 'react';
import useBookingUserData from '../components/Bookings/hooks/useBookingUserData';
import { makeStyles, Typography, Button } from '@material-ui/core';
// import Carousel from '../components/Carousel';
import CarouselManaged from '../components/CarouselManaged';
import { FEATURED_TYPES, NAV_OPTIONS, STATUS } from '../utils/constants';
import FeaturedList from '../components/FeaturedList';
// import { featuredProductRanks } from "../utils/example-data";
import SimpleCard from '../components/SimpleCard';
import { parseIdFromLink } from '../utils/helpers';
import SkeletonSimpleCard from '../components/SkeletonSimpleCard';
import api from '../utils/api';
import { Helmet } from 'react-helmet';

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentInnerContainer: {
    width: '100%',
    maxWidth: theme.screen.maxWidth,
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
  },

  gridTitle: {
    margin: theme.spacing(2, 2, 4, 2),
    color: theme.palette.primary.main,
    fontWeight: '700',
  },
  brandGrid: {
    margin: theme.spacing(2),
    marginBottom: theme.spacing(10),
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridGap: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
  emptyList: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    height: 200,
  },
  anchor: {
    top: -155,
    [theme.breakpoints.down('md')]: {
      top: -125,
    },
    display: 'block',
    position: 'relative',
    visibility: 'hidden',
  },
  anchorButtonContainer: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
    margin: theme.spacing(0, 1, 1, 1),
  },
  anchorButton: {
    color: '#000000 !important',
    textDecoration: 'none',
    borderRadius: 0,
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1, 1, 0, 0),
    },
    padding: theme.spacing(1),
  },
}));

const getBrands = async () => {
  return api
    .getBrands(true)
    .then((res) => res)
    .catch(() => {
      throw STATUS.ERROR;
    });
};

const getFeaturedProducts = async () => {
  return api
    .getFeaturedProducts()
    .then((res) => res.filter((feat) => feat.product && feat.product.removeFromMenu !== true))
    .catch(() => {
      throw STATUS.ERROR;
    });
  // return featuredProductRanks;
};

export default function BrandsView(props) {
  //========Bookings
  const { fetchAndBuildList, allowBookings } = useBookingUserData();

  const classes = useStyles();

  const [brands, setBrands] = useState([]);
  const [latest, setLatest] = useState([]); //[]Platinum List
  const [popular, setPopular] = useState([]);
  const [loading, setLoading] = useState(true);

  const [products, setProducts] = useState([]);
  const [loadingProds, setLoadingProds] = useState(true);

  useEffect(() => {
    let mounted = true;
    const loadBrandsView = async () => {
      const isBookingsPermitted = await allowBookings('brands');

      //[] In refactor Move .thens into async/ await fetches to clean up.
      getBrands().then((res) => {
        const allBrands = res.concat().sort((first, second) => first.name.localeCompare(second.name));
        const legacyPlatinumList = res
          .concat()
          .filter((brand) => brand.latestRank !== null && brand.latestRank !== undefined)
          .sort((x, y) => x.latestRank - y.latestRank);

        const legacyPopularList = res
          .concat()
          .filter((brand) => brand.popularRank !== null && brand.popularRank !== undefined)
          .sort((x, y) => x.popularRank - y.popularRank);

        if (!isBookingsPermitted && mounted) {
          setLatest(legacyPlatinumList);
          setPopular(legacyPopularList);
        }
        if (mounted) setBrands(allBrands);
        // setLoading(false);
      });

      if (isBookingsPermitted) {
        fetchAndBuildList(new Date(), 0, null, null, 'brand').then((lists) => {
          const { popularStores, platinumStores } = lists;
          if (mounted) {
            setLatest(platinumStores);
            setPopular(popularStores);
          }
        });
      }

      getFeaturedProducts().then((res) => {
        if (mounted) {
          setProducts(res.filter((prod) => prod.brand.published === true));
          setLoadingProds(false);
        }
      });
      if (mounted) setLoading(false);
    };

    loadBrandsView();
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line
  }, []);

  const flower = products
    .filter((prod) => prod.type === 'Flower' && prod.product.categories.category === 'Flower')
    .sort((x, y) => x.rank - y.rank);

  const edibles = products
    .filter((prod) => prod.type === 'Edibles' && prod.product.categories.category === 'Edibles')
    .sort((x, y) => x.rank - y.rank);

  const concentrates = products
    .filter((prod) => prod.type === 'Concentrates' && prod.product.categories.category === 'Concentrates')
    .sort((x, y) => x.rank - y.rank);

  const cbd = products.filter((prod) => prod.type === 'CBD').sort((x, y) => x.rank - y.rank);

  const vapepens = products
    .filter((prod) => prod.type === 'Vape Pens' && prod.product.categories.category === 'Vape Pens')
    .sort((x, y) => x.rank - y.rank);

  const bannerData = [
    {
      name: 'brands-near-you',
      header: 'Discover weed brands near you'.toUpperCase(),
      type: 'brands',
      // caption: '',
      image: 'brandsHeader.webp',
      variant: 'h1',
    },
    {
      name: 'brands-near-you',
      header: 'Discover weed brands near you'.toUpperCase(),
      type: 'brands',
      // caption: '',
      image: 'brandsHeader.webp',
      variant: 'h1',
    },
  ];

  return (
    <div className={classes.root}>
      <Helmet>
        <title>{`Weed Stores - Find Canadian Cannabis Brands | S7DAW`}</title>
        <link rel="canonical" href={window.location.href} />
        <meta
          name="description"
          content="Where you'll find your next favourite cannabis brand. We're listing the best weed brands in Canada on S7DAW - Find them today and order now."
        />
      </Helmet>
      {/* <Carousel banners={bannerData} type={'Brands'} override="DISCOVER WEED BRANDS NEAR YOU"></Carousel> */}
      <CarouselManaged id="topBanners" banners={bannerData} type={'Brands'} override="DISCOVER WEED BRANDS NEAR YOU" />

      {/* <Header type={HEADER_TYPE.BRAND} text="DISCOVER WEED BRANDS NEAR YOU"></Header> */}
      <div className={classes.content}>
        <div className={classes.contentInnerContainer}>
          <div className={classes.anchorButtonContainer}>
            {(loading || latest.length > 0) && (
              <Button variant="outlined" href="#all" className={classes.anchorButton}>
                All Brands
              </Button>
            )}
            {(loading || flower.length > 0) && (
              <Button variant="outlined" href="#flowers" className={classes.anchorButton}>
                Flowers
              </Button>
            )}
            {(loading || edibles.length > 0) && (
              <Button variant="outlined" href="#edibles" className={classes.anchorButton}>
                Edibles
              </Button>
            )}
            {(loading || concentrates.length > 0) && (
              <Button variant="outlined" href="#concentrates" className={classes.anchorButton}>
                Concentrates
              </Button>
            )}
            {(loading || cbd.length > 0) && (
              <Button variant="outlined" className={classes.anchorButton} href="#cbd">
                CBD
              </Button>
            )}
            {(loading || vapepens.length > 0) && (
              <Button variant="outlined" href="#vape-pens" className={classes.anchorButton}>
                Vape Pens
              </Button>
            )}
          </div>
          {(loading || latest.length > 0) && (
            <Fragment>
              <p id="latest" className={classes.anchor}></p>
              <FeaturedList
                loading={loading}
                index={1}
                listData={latest}
                title="Platinum Brands"
                image="latest"
                type={FEATURED_TYPES.BRAND}
                viewAllPath={'NONE'}
                handleNavBarChange={() => {}}
              ></FeaturedList>
            </Fragment>
          )}
          {(loading || popular.length > 0) && (
            <Fragment>
              <p id="popular" className={classes.anchor}></p>
              <FeaturedList
                loading={loading}
                index={2}
                listData={popular}
                title="Most Popular"
                image="popular"
                type={FEATURED_TYPES.BRAND}
                viewAllPath={'NONE'}
                handleNavBarChange={
                  (/*event*/) => {
                    // handleNavBarChange(event, getNavBarIndex(FEATURED_TYPES.BRAND))
                  }
                }
              ></FeaturedList>
            </Fragment>
          )}
          {(loadingProds || flower.length > 0) && (
            <Fragment>
              <p id="flowers" className={classes.anchor}></p>
              <FeaturedList
                loading={loadingProds}
                index={3}
                listData={flower}
                title="Flowers"
                image="flowers"
                type={FEATURED_TYPES.FEATUREDPRODUCT}
                viewAllPath={'NONE'}
                handleNavBarChange={() => {}}
              />
            </Fragment>
          )}
          {(loadingProds || edibles.length > 0) && (
            <Fragment>
              <p id="edibles" className={classes.anchor}></p>
              <FeaturedList
                loading={loadingProds}
                index={4}
                listData={edibles}
                title="Edibles"
                image="edibles"
                type={FEATURED_TYPES.FEATUREDPRODUCT}
                viewAllPath={'NONE'}
                handleNavBarChange={() => {}}
              />
            </Fragment>
          )}
          {(loadingProds || concentrates.length > 0) && (
            <Fragment>
              <p id="concentrates" className={classes.anchor}></p>
              <FeaturedList
                loading={loadingProds}
                index={5}
                listData={concentrates}
                title="Concentrates"
                image="concentrates"
                type={FEATURED_TYPES.FEATUREDPRODUCT}
                viewAllPath={'NONE'}
                handleNavBarChange={() => {}}
              />
            </Fragment>
          )}
          {(loadingProds || cbd.length > 0) && (
            <Fragment>
              <p id="cbd" className={classes.anchor}></p>
              <FeaturedList
                loading={loadingProds}
                index={6}
                listData={cbd}
                title="CBD"
                image="cbd"
                type={FEATURED_TYPES.FEATUREDPRODUCT}
                viewAllPath={'NONE'}
                handleNavBarChange={() => {}}
              />
            </Fragment>
          )}
          {(loadingProds || vapepens.length > 0) && (
            <Fragment>
              <p id="vape-pens" className={classes.anchor}></p>
              <FeaturedList
                loading={loadingProds}
                index={7}
                listData={vapepens}
                title="Vape Pens"
                image="vape pens"
                type={FEATURED_TYPES.FEATUREDPRODUCT}
                viewAllPath={'NONE'}
                handleNavBarChange={() => {}}
              />
            </Fragment>
          )}
          <p id="all" className={classes.anchor}></p>
          <Typography variant="h4" className={classes.gridTitle}>
            ALL BRANDS
          </Typography>
          {!loading && brands.length === 0 && !loadingProds && products.length === 0 && (
            <Typography className={classes.emptyList}>
              No brands available at this time. Please check back later.
            </Typography>
          )}
          <div className={classes.brandGrid}>
            {!loading &&
              brands.length > 0 &&
              brands.map((brand, index) => {
                return (
                  <SimpleCard
                    key={index}
                    item={brand}
                    itemPath={`${NAV_OPTIONS.find((option) => option.name === 'Brands').path}/${parseIdFromLink(
                      brand._links.self.href
                    )}/${brand.slug}`}
                    type={FEATURED_TYPES.BRAND}
                  ></SimpleCard>
                );
              })}
            {loading && (
              <Fragment>
                <SkeletonSimpleCard></SkeletonSimpleCard>
                <SkeletonSimpleCard></SkeletonSimpleCard>
                <SkeletonSimpleCard></SkeletonSimpleCard>
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
