import React, { useState, useEffect, useContext } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import CategoryBreadcrumbs from '../components/CategoryBreadcrumbs';
import ProductProfile from '../components/ProductProfile';
import ProductDetailsTabPanel from '../components/ProductDetailsTabPanel';
import api from '../utils/api';
import { STATUS } from '../utils/constants';
import { parseIdFromLink } from '../utils/helpers';
import PreviewContext from '../PreviewContext';
import { Helmet } from 'react-helmet';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
  },
  innerContainer: {
    width: '100%',
    maxWidth: theme.screen.maxWidth,
  },
  breadCrumbs: {
    marginTop: 0,
    margin: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  productProfile: {
    marginBottom: theme.spacing(5),
  },
  tabPanel: { marginBottom: theme.spacing(15) },
}));

const getProduct = async (id) => {
  return api
    .getProductCustomized(id)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

const getProductImages = async (id) => {
  return api
    .getProduct(id)
    .then((res) => {
      const sdawFiles = res.data._embedded.files;
      const posConnection = res.data.posConnection;

      const posImages = [
        posConnection?.posItemFile1,
        posConnection?.posItemFile2,
        posConnection?.posItemFile3,
        posConnection?.posItemFile4,
        posConnection?.posItemFile5,
      ]
        .filter((x) => x)
        .map((x, i) => {
          return { name: `productImage${i}}`, rank: i, url: x, externalSource: true };
        });
      return posImages.length > 0 ? posImages : sdawFiles;
    })
    .catch(() => {
      throw STATUS.ERROR;
    });
};

function ProductDetailsView(props) {
  const classes = useStyles();

  const { /*history,*/ brand, previewMode } = props;

  const [product, setProduct] = useState({});
  const [productImages, setProductImages] = useState([]);

  const [loading, setLoading] = useState(true);
  const [loadingImgs, setLoadingImgs] = useState(true);
  const [imageError, setImageError] = useState(false);

  const preview = useContext(PreviewContext);

  const [custom, setCustom] = useState({});

  const productId = !preview.state.screen ? props.match.params.id : preview.state.productId;
  const storeId = !preview.state.screen ? props.match.params.storeId : props.match.params.id;
  const storeSlug = !preview.state.screen ? props.match.params.storeSlug : props.match.params.storeId;

  useEffect(() => {
    const updateProductInfo = async () => {
      const { province, city } = props.match.params;
      const entityTypeFromUrl = window.location.href.split('/')[3] && window.location.href.split('/')[3].toLowerCase();
      const fallbackPath = `/${entityTypeFromUrl ?? 'stores'}/${province}/${city}`; // Used if not published or item doesn't exist
      /* 
      Old general directory doesn't work atm. so prov and city are added (ie /deliveries)
       */

      try {
        const productInfoRes = await getProduct(productId);
        if (productInfoRes.removeFromMenu === true && !previewMode) {
          //Redirect if product is not published

          window.location.assign(fallbackPath);
        }

        //Conditions to classify the type of product the was retreived
        const isBrand = (productInfoRes.brand && productInfoRes.brand.slug === storeSlug) || previewMode ? true : false;

        const isStoreUsingBrandedProduct =
          productInfoRes.stores &&
          productInfoRes.stores.length > 0 &&
          productInfoRes.brand &&
          productInfoRes.brand.length > 0 &&
          productInfoRes.brand.slug !== storeSlug
            ? true
            : false; //There has got to be a better way of handling this... refactor when time permits.
        //is the title accurate? is this a branded product? or just a store that uses any branded product?  Why does it matter?

        const isRegularStoreProduct =
          productInfoRes.stores && productInfoRes.stores.length > 0 && storeSlug ? true : false;

        if (isBrand) {
          return setProduct(productInfoRes);
        }

        if (isStoreUsingBrandedProduct) {
          const storeInfo = productInfoRes.stores.find((store) => store.slug === storeSlug); // [] TODO, change this; should all Stores be fetched with the branded product?
          const isStorePublished = storeInfo.published;
          if (isStorePublished) {
            setCustom(productInfoRes.productCustomizations.find((custom) => custom.store.slug === storeSlug));
            setProduct(productInfoRes);

            // ao: sdaw-1162 - redirect to the correct province name
            // works with this: http://localhost:3000/deliveries/ontario/hamilton/23183/medmeneh-delivery but keep testing.
            const urlProv = window.location.href.split('/')[4] && window.location.href.split('/')[4].toLowerCase();

            if (urlProv !== productInfoRes.address.province.replace(/ /gi, '-').toLowerCase()) {
              window.location.href = `/stores/${productInfoRes.address.province.replace(/ /gi, '-').toLowerCase()}/${
                window.location.href.split('/')[5] && window.location.href.split('/')[5]
              }`;
            }
            return;
          }
          //Redirect store is NOT published
          window.location.assign(fallbackPath);
        }

        if (isRegularStoreProduct) {
          return setProduct(productInfoRes);
        }
        //Unknown store type (not brand, store with brand or reg store)
        window.location.assign(fallbackPath); //Was previously root patch,
      } catch (error) {
        if (error.includes('Request failed with status code 404')) {
          window.location.assign(fallbackPath);
        }
      } finally {
        setLoading(false);
      }
    };

    const getAndSetProductImages = async () => {
      try {
        const res = await getProductImages(productId);
        setLoadingImgs(false);
        setProductImages(res.sort((x, y) => x.rank - y.rank).map((img) => img.url));
      } catch (e) {
        setImageError(true);
      }
    };

    if (productId) {
      updateProductInfo();
      getAndSetProductImages();
    }
  }, [productId, brand, storeId, previewMode, storeSlug, props.match.params]);

  // ao: SDAW-1044 - added condition for when store is unknown (use first store on the list).
  const store = storeSlug
    ? !loading &&
      product.stores &&
      product.stores.find(
        (store) => parseIdFromLink(store._links.self.href, 0, '{?projection}') === storeId || store.slug === storeSlug
      )
    : !loading && product.stores && product.stores[0];

  return (
    <div className={classes.root}>
      <Helmet>
        <title>{`${
          product.name
            ? `${product.name.slice(0, 30 > product.name.length ? product.name.length : 30)}${
                30 < product.name.length ? '...' : ''
              } | ${product.categories.category} | `
            : ''
        }S7DAW `}</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content={product.description} />
        {/* ao: sdaw-1205 - added no index tag to fix Google Search Console warnings. Also need to remove these pages from robots.txt  */}
        <meta name="robots" content="noindex" />
      </Helmet>
      {loading && <Typography style={{ marginTop: '20px' }}>Loading...</Typography>}

      {!loading && (
        <div className={classes.innerContainer}>
          <div className={classes.breadCrumbs}>
            <CategoryBreadcrumbs
              store={{
                id: brand
                  ? parseIdFromLink(product.brand._links.self.href, 0, '{?projection}')
                  : parseIdFromLink(store._links.self.href, 0, '{?projection}'),
                name: brand ? product.brand.name : store.name,
                type: brand ? 'brand' : store.storeType,
                slug: brand ? null : store.slug, //what about brand handling
              }}
              categories={product.categories}
              productName={product.name}
            ></CategoryBreadcrumbs>
          </div>
          <div className={classes.productProfile}>
            <ProductProfile
              brand={brand}
              product={product}
              images={productImages}
              loadingImgs={loadingImgs}
              imageError={imageError}
              custom={custom}
              storeId={storeId}
              storeSlug={storeSlug}
            />
          </div>
          <div className={classes.tabPanel}>
            <ProductDetailsTabPanel
              brand={brand}
              product={product}
              id={productId}
              storeId={!brand ? storeId : ''}
            ></ProductDetailsTabPanel>
          </div>
        </div>
      )}
    </div>
  );
}

export default ProductDetailsView;
