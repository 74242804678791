import React, { useState, Fragment, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import api from '../utils/api';
import cookies from '../cookies';
import {
  InputAdornment,
  Tooltip,
  // FormControlLabel,
  // Checkbox
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
// import { ROLES } from "../utils/constants";
import { Helmet } from 'react-helmet';
import { validateEmail } from '../utils/helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalPaper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  actionButtons: { display: 'flex' },
  confirmationMessage: {
    margin: theme.spacing(2),
  },
  confirmationButton: {
    color: theme.palette.common.white,

    margin: theme.spacing(2),
  },
  okconfirmationButton: {
    color: theme.palette.common.black,
    backgroundColor: '#DDDDDD',
    margin: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(8),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 600,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  endAdornment: {
    color: theme.palette.grey.A700,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  registerMsg: {
    margin: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function LoginView(props) {
  const { setJWT, setUserId, setRole, history, loginRoute, location } = props;

  if (cookies.getJWT() && cookies.getUserID() && cookies.getRole()) {
    history.goBack();
  }

  const classes = useStyles();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordMask, setPasswordMask] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const [resendSubmitted, setResendSubmitted] = useState(false);
  const [resendEmail, setResendEmail] = useState('');
  const [resendModal, setResendModal] = useState(false);
  // const[resendMsg, setResendMsg] = useState(""); //Top modal modal text
  const [resendErrorMsg, setResendErrorMsg] = useState(''); //Red Error text
  const [resendError, setResendError] = useState(false);

  const [loginError, setLoginError] = useState(false);
  const [timer, setTimer] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [notVerif, setNotVerif] = useState(false);
  const [buttonText, setButtonText] = useState('Resend Email');

  const [triggerLogin, setTriggerLogin] = useState(null);

  const validate = () => {
    let valid = validateEmail(email) && validatePassword();

    if (!valid) {
      setLoading(false);
    }
    return valid;
  };

  const validatePassword = () => {
    return password !== '';
  };

  const resendConfirmEmail = async (email) => {
    return api
      .sendConfirmation(email)
      .then((res) => {
        setResendError(false);
        setResendErrorMsg('');
      })
      .catch((error) => {
        setResendError(true);
        if (error.message.includes('User')) {
          setResendError(true);
          setResendErrorMsg('There is no account associated to this email address.');
          setTimer(false);
          setButtonText('Resend Email');
        }
      });
  };

  useEffect(() => {
    const login = async () => {
      const updateCookies = ({ token, userID, role }) => {
        cookies.setJWT(token);
        cookies.setUserID(userID);
        // Set state in app.js
        setJWT(token);
        setUserId(userID);
        setRole(role);
      };
      try {
        setLoginError(false);
        setLoading(true);

        const loginRes = await api.login(triggerLogin.email.toLowerCase(), triggerLogin.password);
        const userRes = await api.getUser(loginRes.data.userID);
        await updateCookies({
          token: loginRes.data.token,
          userID: loginRes.data.userID,
          role: userRes._embedded.role.name,
        });
        setLoading(false);
        setTriggerLogin(null);
        history.push(location.prevPath || '/');
      } catch (error) {
        if (error.message.includes('User')) {
          setNotVerif(true);
          setResendModal(true);
          setErrorMsg('Please verify your email address before attempting to login');
        }
        if (error.message.includes('Unauthorized')) {
          setErrorMsg('Please enter valid credentials');
        }
        setLoginError(true);
        setLoading(false);
        setTriggerLogin(null);
      }
    };

    if (triggerLogin !== null) {
      login();
    }

    return () => {
      //cleanup
    };
  }, [triggerLogin, setJWT, setUserId, setRole, history, location.prevPath]);

  const isEmailValid = validateEmail(email);
  const isPasswordValid = validatePassword();

  return (
    <div className={classes.root}>
      <Helmet>
        <title>S7DAW | Login</title>
        <link rel="canonical" href={window.location.href} />
        <meta
          name="description"
          content="Login to review dispensaries, products and stay in the know for all things s7daw.com"
        />
      </Helmet>
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            value={email}
            onChange={(event) => {
              setLoginError(false);
              setEmail(event.target.value);
            }}
            inputProps={{ 'data-testid': 'email' }}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email-input"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            error={(submitted && !isEmailValid) || loginError}
            helperText={submitted && !isEmailValid ? 'Enter a valid email address' : ''}
          />
          <TextField
            value={password}
            onChange={(event) => {
              setLoginError(false);
              setPassword(event.target.value);
            }}
            variant="outlined"
            margin="normal"
            inputProps={{ 'data-testid': 'password' }}
            required
            fullWidth
            name="password"
            label="Password"
            type={passwordMask ? 'password' : 'text'}
            id="password-input"
            autoComplete="current-password"
            error={(submitted && !isPasswordValid) || loginError}
            helperText={submitted && !isPasswordValid ? 'Enter a password' : loginError ? errorMsg : ''}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {passwordMask && (
                    <Tooltip title="Show password" placement="bottom" className={classes.endAdornment}>
                      <VisibilityIcon onClick={() => setPasswordMask(!passwordMask)} />
                    </Tooltip>
                  )}
                  {!passwordMask && (
                    <Tooltip title="Hide password" placement="bottom">
                      <VisibilityOffIcon
                        onClick={() => setPasswordMask(!passwordMask)}
                        className={classes.endAdornment}
                      />
                    </Tooltip>
                  )}
                </InputAdornment>
              ),
            }}
          />

          {/* =================================== MODAL ================================== */}
          <Modal
            className={classes.modal}
            open={notVerif && resendModal}
            onClose={() => {
              setResendModal(false);
            }}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={notVerif && resendModal}>
              <div className={classes.modalPaper}>
                <Typography className={classes.confirmationMessage}>Please verify your email address.</Typography>
                <TextField
                  value={resendEmail}
                  onChange={(event) => {
                    setResendEmail(event.target.value);
                  }}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="email"
                  label="Email"
                  type={'text'}
                  id="email"
                  autoComplete="current-email"
                  error={(!validateEmail(resendEmail) && resendSubmitted) || resendError}
                  helperText={resendErrorMsg}
                />
                <div className={classes.actionButtons}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setResendModal(false);
                    }}
                    className={classes.okconfirmationButton}
                  >
                    Ok
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    //hidden = {true ? notVerif : false}
                    className={classes.confirmationButton}
                    disabled={!validateEmail(resendEmail) || timer}
                    onClick={() => {
                      resendConfirmEmail(resendEmail);
                      setResendSubmitted(true);
                      //if(!error){
                      setTimer(true);
                      setButtonText('60s for next Resend');
                      setTimeout(() => {
                        setTimer(false);
                        setButtonText('Resend Email');
                      }, 60000);
                      // }
                    }}
                  >
                    {buttonText}
                  </Button>
                </div>
              </div>
            </Fade>
          </Modal>
          {/* ==================================================================== */}

          <Button
            id="sign-in-button"
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={(event) => {
              event.preventDefault();
              setResendEmail(email);
              setSubmitted(true);
              if (validate()) {
                setTriggerLogin({ email, password });
              }
            }}
          >
            {loading ? <CircularProgress color="secondary" /> : `Sign In`}
          </Button>
        </form>
        {loginRoute && (
          <Grid container>
            <Grid item xs>
              <Link href="/forgotPassword" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="/signup" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        )}
        {!loginRoute && (
          <Fragment>
            <Typography variant="body2" className={classes.registerMsg}>
              Don't have an account yet?
            </Typography>
            <Typography variant="body2" className={classes.registerMsg}>
              Email us at <a href="mailto: info@s7daw.com">info@s7daw.com</a> with your business details to get set up!
            </Typography>
          </Fragment>
        )}
      </div>
      <Box mt={8}>
        <Typography variant="body2" color="textSecondary" align="center">
          {'Copyright © '}
          <Link color="inherit" href="/">
            SDAW
          </Link>{' '}
          2020.
        </Typography>
      </Box>
    </div>
  );
}
