import React, { Fragment, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Tooltip } from '@material-ui/core';
import { capitalizeFirst, parseIdFromLink, makeImageKitURL } from '../utils/helpers';
import { PRODUCT_FLAGS, SCREEN_STATES } from '../utils/constants';
import { Link } from 'react-router-dom';
import PreviewContext from '../PreviewContext';

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    margin: theme.spacing(0.1),
    boxShadow: '0px 0px',
    border: '1px solid #e0e0e0',
    borderRadius: 0,
    overflow: 'visible',
  },
  cardAction: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: theme.spacing(2),
    // paddingLeft: theme.spacing(1),
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  media: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    minWidth: 125,
    height: 125,
    border: '1px solid #e0e0e0',
    [theme.breakpoints.down('sm')]: {
      minWidth: 75,
      height: 75,
    },
  },
  cardContent: {
    height: 125,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    paddingLeft: theme.spacing(1),
    // paddingTop: theme.spacing(1.5),
    // paddingBottom: theme.spacing(1.5),
    [theme.breakpoints.down('sm')]: {
      // paddingTop: theme.spacing(4),
      // paddingBottom: theme.spacing(4),
      height: 100,
    },
  },
  nameAndPrice: {
    height: '100%',
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  name: {
    fontWeight: 'bold',
    fontSize: '16pt',
    // flexGrow: 1,
    [theme.breakpoints.up('md')]: {
      maxWidth: 300,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '10pt',
      // flexGrow: 1,
      // marginBottom: theme.spacing(1),
    },
  },
  nameAndStrain: {
    display: 'flex',
    // flexGrow: 1,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-end',
      // marginTop: theme.spacing(2)
    },
    justifyContent: 'space-between',
  },
  strainContentMobile: {
    display: 'flex',
    maxWidth: 120,
    marginLeft: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  productDetails: {
    height: '100%',
    display: 'flex',
    width: 300,
    [theme.breakpoints.down('md')]: {
      width: 150,
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  strainDetails: {
    flexGrow: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    paddingLeft: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      display: theme.spacing(1),
    },
  },
  effectsAndUses: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: "space-around",
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  strainContent: { display: 'flex', marginRight: theme.spacing(2) },
  timeOfUse: { display: 'flex' },
  imageSection: {
    // width: "30%",
    display: 'flex',
    justifyContent: 'center',
  },
  infoSection: {
    // width: "70%",
    // flexGrow: 1,
    minWidth: 58,
    paddingLeft: theme.spacing(0.5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  tableCellHead: {
    borderBottom: '0px',
    padding: '10px 10px 0px 0px',
    lineHeight: '1rem',
    fontSize: '0.75rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.6rem',
    },
  },
  tableCell: {
    borderBottom: '0px',
    padding: '0px 10px 0px 0px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.6rem',
    },
  },
  moreCell: {
    borderBottom: '0px',
    padding: '0px 0px 0px 0px',
    lineHeight: '1rem',
    fontSize: '0.75rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.6rem',
    },
  },
  symbolList: {
    display: 'flex',
    marginTop: 2,
    marginBottom: 2,
  },
  symbol: {
    width: 42,
    marginRight: 10,
  },
  productBadge: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  verifiedBadge: {
    color: '#00aced',
    height: 45,
    width: 45,
    position: 'absolute',
    top: -10,
    right: -12,
  },
  brandName: {
    position: 'absolute',
    top: 2,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  mobileInfoSectionText: {
    fontSize: '7pt',
    fontWeight: 'bold',
  },
}));

function ProductListItem(props) {
  const { product, storeId, brandId, storeSlug, province, city } = props;
  const classes = useStyles();

  /* Started cleaning up product patch, but entity type needs to be fixed in the router
  - deliveries
  - mailouts
  Note clinics don't have products, need to handle redirect if manually typed
   */
  const productID = parseIdFromLink(product._links.self.href);
  const isItemBranded = product?.brand ? true : false;
  // const entityTypeFromUrl = window.location.href.split('/')[3] && window.location.href.split('/')[3].toLowerCase();

  const startOfUrl = isItemBranded ? `/brands/${storeSlug}` : `/${'stores'}/${province}/${city}/${storeSlug}`;
  const productInfoUrl = `/${productID}/${product.slug}`;
  const productPath = startOfUrl + productInfoUrl;

  const preview = useContext(PreviewContext);

  let priceList = product && product.productPricingList && product.productPricingList.sort((x, y) => x.price - y.price);

  if (
    product.productCustomizations &&
    product.productCustomizations.find(
      (customization) => parseIdFromLink(customization.store._links.self.href, 0, '{?projection}') === storeId
    )
  ) {
    priceList = product.productCustomizations
      .find((customization) => parseIdFromLink(customization.store._links.self.href, 0, '{?projection}') === storeId)
      .productPricingList.sort((x, y) => x.price - y.price);
  }

  let verified =
    storeId &&
    product.productCustomizations.find(
      (customization) => parseIdFromLink(customization.store._links.self.href, 0, '{?projection}') === storeId
    ) &&
    product.productCustomizations.find(
      (customization) => parseIdFromLink(customization.store._links.self.href, 0, '{?projection}') === storeId
    ).verified;

  let flags = product.flags;

  if (
    product.productCustomizations &&
    product.productCustomizations.length > 0 &&
    product.productCustomizations.find(
      (customization) => parseIdFromLink(customization.store._links.self.href, 0, '{?projection}') === storeId
    )
  ) {
    flags = product.productCustomizations.find(
      (customization) => parseIdFromLink(customization.store._links.self.href, 0, '{?projection}') === storeId
    ).flags;
  }
  const posHeroImage = product?.posConnection?.posItemFile1;
  const productImageURL = posHeroImage || makeImageKitURL(product.imgURL, { width: '150' }, 'PRODUCT');

  return (
    <Card
      className={classes.card}
      component={!preview.state.screen ? Link : undefined}
      to={!preview.state.screen ? productPath : undefined}
      style={{ color: '#000000', textDecoration: 'none' }}
      onClick={
        !preview.state.screen
          ? undefined
          : () =>
              preview.changeScreen(
                SCREEN_STATES.PRODUCT_DETAILS,
                parseIdFromLink(product._links.self.href, 0, '{?projection}')
              )
      }
    >
      <CardActionArea className={classes.cardAction}>
        {verified && (
          <Tooltip className={classes.verifiedBadge} placement="top" arrow title="Verified Reseller">
            <img src={require(`../assets/badges/verifiedBlueCheck.svg`)} alt={`verified-blue-check`} />
          </Tooltip>
        )}
        {!brandId && product.brand && (
          <Typography
            className={classes.brandName}
            variant="caption"
            style={{
              right: verified ? 35 : 10,
              color: '#5b5b5b',
            }}
          >
            Made By: {product.brand.name}
          </Typography>
        )}
        {flags && flags.includes(PRODUCT_FLAGS.LIMITED) && (
          <img
            src={require(`../assets/product-banners/productlisting_limited.png`)}
            alt={product.name}
            className={classes.productBadge}
          ></img>
        )}
        {flags && flags.includes(PRODUCT_FLAGS.NEW) && (
          <img
            src={require(`../assets/product-banners/productlisting_new.png`)}
            alt={product.name}
            className={classes.productBadge}
          ></img>
        )}
        {flags && flags.includes(PRODUCT_FLAGS.OUTOFSTOCK) && (
          <img
            src={require(`../assets/product-banners/productlisting_soldout.png`)}
            alt={product.name}
            className={classes.productBadge}
          ></img>
        )}
        {flags && flags.includes(PRODUCT_FLAGS.SPECIAL) && (
          <img
            src={require(`../assets/product-banners/productlisting_special.png`)}
            alt={product.name}
            className={classes.productBadge}
          ></img>
        )}
        {/* [ ] TODO - Lazy Load Card Media */}
        <CardMedia className={classes.media} image={productImageURL} alt={product.name} id="card-media" />
        <CardContent className={classes.cardContent}>
          <div className={classes.nameAndPrice}>
            <div className={classes.nameAndStrain}>
              <Typography className={classes.name}>
                {/* {product.name} */}
                {product.name.slice(0, 40)}
                {product.name.length > 40 && '...'}
              </Typography>
            </div>
            {priceList && (
              <Table size="small" aria-label="pricing table">
                <TableHead>
                  <TableRow variant="body2">
                    {priceList.slice(0, 5).map((product, index) => (
                      <TableCell className={classes.tableCellHead} key={index} component="th">
                        {product.quantity ? product.quantity : 'Each'}
                      </TableCell>
                    ))}
                    {priceList.length > 5 && (
                      <TableCell className={classes.tableCellHead} component="th">
                        {''}
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {priceList.slice(0, 5).map((product, index) => (
                      <TableCell className={classes.tableCell} key={index}>
                        <strong>
                          ${product.price === Math.floor(product.price) ? product.price : product.price?.toFixed(2)}
                        </strong>
                      </TableCell>
                    ))}
                    {priceList.length > 5 && (
                      <TableCell className={classes.moreCell}>
                        <strong>+ More</strong>
                      </TableCell>
                    )}
                  </TableRow>
                </TableBody>
              </Table>
            )}
          </div>
          <div className={classes.productDetails}>
            <div className={classes.strainDetails}>
              <div className={classes.strainContent}>
                <div className={classes.imageSection}>
                  {product.genetics && (
                    <Tooltip title={capitalizeFirst(product.genetics)} placement="left">
                      <img
                        src={require(`../assets/genetic-types/${product.genetics.toLowerCase().replace(/ /g, '')}.svg`)}
                        alt={`strain-${product.genetics}`}
                        className={classes.symbol}
                      />
                    </Tooltip>
                  )}
                </div>
                <div className={classes.infoSection}>
                  <Typography variant="caption">{product.genetics ? product.genetics : ''}</Typography>
                  {product.thc > 0 && product.thc && <Typography variant="caption">THC: {product.thc}%</Typography>}
                  {product.cbd > 0 && product.cbd && <Typography variant="caption">CBD: {product.cbd}%</Typography>}
                </div>
              </div>
              <div className={classes.timeOfUse}>
                <div className={classes.imageSection}>
                  {product.timeOfUse && (
                    <Tooltip title={capitalizeFirst(product.timeOfUse)} placement="left">
                      <img
                        src={require(`../assets/time-of-use/${product.timeOfUse.toLowerCase().replace(/ /g, '')}.svg`)}
                        alt={`strain-${product.timeOfUse}`}
                        className={classes.symbol}
                      />
                    </Tooltip>
                  )}
                </div>
                <div className={classes.infoSection}>
                  {product.timeOfUse && (
                    <Fragment>
                      <Typography variant="caption">Time of Use:</Typography>
                      <Typography variant="caption">{product.timeOfUse}</Typography>
                    </Fragment>
                  )}
                </div>
              </div>
            </div>
            <div className={classes.effectsAndUses}>
              <div className={classes.effects}>
                {product.effects.length > 0 && (
                  <Fragment>
                    <Typography variant="caption">Typical Effects</Typography>
                    <div className={classes.symbolList}>
                      {product.effects &&
                        product.effects.map((effect, index) => (
                          <Tooltip title={capitalizeFirst(effect)} arrow key={index}>
                            <img
                              src={require(`../assets/effects/${effect.toLowerCase().replace(/ /g, '')}.svg`)}
                              alt={`effect-${effect}`}
                              className={classes.symbol}
                            ></img>
                          </Tooltip>
                        ))}
                      {!product.effects && <Typography variant="caption">N/A</Typography>}
                    </div>
                  </Fragment>
                )}
              </div>
              <div className={classes.uses}>
                {product.uses.length > 0 && (
                  <Fragment>
                    <Typography variant="caption">Common Uses</Typography>
                    <div className={classes.symbolList}>
                      {product.uses &&
                        product.uses.map((use, index) => (
                          <Tooltip title={capitalizeFirst(use)} arrow key={index}>
                            <img
                              src={require(`../assets/uses/${use.toLowerCase().replace(/ /g, '')}.svg`)}
                              alt={`use-${use}`}
                              className={classes.symbol}
                            ></img>
                          </Tooltip>
                        ))}
                      {!product.uses && <Typography variant="caption">N/A</Typography>}
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
          </div>
          <div className={classes.strainContentMobile}>
            {/* <div className={classes.imageSection}>
              {product.genetics && (
                <Tooltip
                  title={capitalizeFirst(product.genetics)}
                  placement="left"
                >
                  <img
                    src={require(`../assets/genetic-types/${product.genetics
                      .toLowerCase()
                      .replace(/ /g, "")}.svg`)}
                    alt={`strain-${product.genetics}`}
                    className={classes.symbol}
                  />
                </Tooltip>
              )}
            </div> */}
            <div className={classes.infoSection}>
              <Typography className={classes.mobileInfoSectionText}>
                {product.genetics ? product.genetics : ''}
              </Typography>
              {product.thc > 0 && product.thc && (
                <Typography className={classes.mobileInfoSectionText}>THC: {product.thc}%</Typography>
              )}
              {product.cbd > 0 && product.cbd && (
                <Typography className={classes.mobileInfoSectionText}>CBD: {product.cbd}%</Typography>
              )}
            </div>
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default ProductListItem;
