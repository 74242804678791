/* This version of carousel uses /topBanners from the API
Theses are the managed by the admin team */
import React, { Fragment, useState, useEffect } from 'react';
import { Carousel } from 'antd';
import 'antd/dist/antd.css';
import './styles/Carousel.css';
import { makeStyles, Typography, Divider } from '@material-ui/core';
import api from '../utils/api';
import Skeleton from '@material-ui/lab/Skeleton';
// import { ContactlessOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  /* overall banner height is controlled by  `import './styles/Carousel.css';` [] TODO Refactor into this file, classname should be on <Carousel> */
  root: {
    width: '100%',
    height: '340px',
    [theme.breakpoints.down('md')]: {
      height: '205px',
    },
    [theme.breakpoints.down('sm')]: {
      height: '154px',
    },
    [theme.breakpoints.down('xs')]: {
      height: '85px',
    },
  },

  bannerSlide: {
    //Heights need to match .Carousel.css Heights
    display: 'flex!important',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    // height: '45vh',
    height: '340px',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      // height: "38vh",
      height: '205px',
    },
    [theme.breakpoints.down('sm')]: {
      // height: "30vh",
      height: '154px',
    },
    [theme.breakpoints.down('xs')]: {
      // height: '25vh',
      // height: '22vh',
      height: '85px',
    },
  },
  bannerImage: {
    height: '100%',
    // width: '100%',
    maxWidth: '1700px',
    flexGrow: 1,
    //max width doesn't do anything
    // [theme.breakpoints.down('md')]: {
    //   maxWidth: '1024px',
    // },
    // [theme.breakpoints.down('sm')]: {
    //   maxWidth: '768px',
    // },
    // [theme.breakpoints.down('xs')]: {
    //   // height: '25vh',
    //   maxWidth: '425px',
    // },
  },
  content: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    zIndex: 2,
    paddingLeft: '15%',
    paddingRight: '15%',
  },
  contentInnerContainer: {
    maxWidth: '1245px',
    position: 'relative',
  },

  //NOTE: any styling with banner_____ is referencing the HOME page banners. All other banners are specified as: storeFrontBanner, delvieryBanner etc
  bannerText: {
    textShadow: '1px 1px #4e652e',
    wordBreak: 'break-word',
    // fontSize: '7vh',
    fontSize: '3rem',
    fontWeight: '600',
    margin: '1rem',
    [theme.breakpoints.down('md')]: {
      // fontSize: '3.5vh',
      fontSize: '2rem',
      margin: '0.6rem',
    },
    [theme.breakpoints.down('sm')]: {
      // fontSize: '3.5vh',
      fontSize: '1rem',
      margin: '0.3rem',
    },
    [theme.breakpoints.down('xs')]: {
      // fontSize: '3.5vh',
      fontSize: '0.75rem',
      margin: '0.25rem',
    },
  },

  bannerButton: {
    boxShadow: '0px 0px',
    marginTop: theme.spacing(2),
    // marginLeft: theme.spacing(10),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(1.5),
      // marginLeft: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1),
      // marginLeft: theme.spacing(2),
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(0.5),
      // marginLeft: theme.spacing(2),
    },
  },

  bannerCaption: {
    fontWeight: 'bold',
    // fontSize: '2.75vh',
    fontSize: '1.125rem',
    [theme.breakpoints.down('md')]: {
      // fontSize: '1.75vh',
      fontSize: '0.9rem',
    },
    [theme.breakpoints.down('sm')]: {
      // fontSize: '1.75vh',
      fontSize: '0.7rem',
    },
    [theme.breakpoints.down('xs')]: {
      // fontSize: '1.75vh',
      fontSize: '0.5rem',
    },
  },

  divider: {
    height: 2,
  },
}));

const fetchBanners = async () => {
  return await api.getTopBanners();
};

function CarouselPage({ banners, type, override, previewBannerName, previewBannerData, bannerImages }) {
  const classes = useStyles();

  const [state, setState] = useState({
    //TODO: we should probably use a constant from utils that is self building whenever something gets added to the nav bar in the future so this list auto updates...
    banners: {
      formattedBanners: [],
    },
    loading: true,
    apiState: {
      apiError: false,
      error: false,
      apiMessage: '',
    },
    hasChanged: 0, //Cfm if this is used
  });

  const getTextPosition = (val) => {
    /*
    Val will be a 2 char code (sce) that will be used to determine flex position
    If no input rtn null > CSS will default to center left (CSS)
     */
    if (!val) {
      return null;
    }
    const options = {
      s: 'flex-start',
      c: 'center',
      e: 'flex-end',
    };
    const vertical = val[0];
    const horizontal = val.slice(-1);

    const flexPosns = {
      alignItems: options[vertical],
      justifyContent: options[horizontal],
    };

    return flexPosns;
  };

  //we get the backend banners and now we need to stitch together the differnce between how we used to provide data to the caroosel and the new banner system..
  useEffect(() => {
    let mounted = true;
    /* TODO - Need to dry up code b/c there are 2x set states that do the exact same thing, setState should go into its own function */

    setState((prev) => ({ ...prev, loading: true }));
    const parsedInfo = {};
    if (previewBannerName) {
      const { bannerText, bannerTextPosition, link, fontSize, fontColor, seo, mobile, file, page, bannerTextCheckBox } =
        previewBannerData;
      const bannerIndex = previewBannerName.slice(-1);
      //set banner using preview prop
      parsedInfo[bannerIndex] = {
        name: 'newBanner',
        text: bannerIndex === '1' ? `SEO Text, ie Weed ${page} <City>` : bannerTextCheckBox && bannerText,
        textPosition: getTextPosition(bannerTextPosition),
        fontSize,
        fontColor,
        seo,
        link,
        file,
        mobile,
        page,
        // id: pageInfo._links.self.href,
      };
    } else {
      fetchBanners()
        .then((res) => {
          const bannersForAllPages = res.data._embedded.topBanners;
          const pageInfo = bannersForAllPages.find((x) => x.page === type);
          if (!pageInfo) return; //Stops no banners found from breaking the app
          //Parse bannerInfo (1 big flat object need to parse)
          // ao: sdaw-1375 for the first 2 default banners:
          for (let i = 1; i < 3; i++) {
            parsedInfo[i] = {
              name: `Banner${i}`,
              // file: { url: banners[i-1], },
              file: { url: require(`../assets/banners/${banners[i - 1].image}`) },
              text: banners[i - 1].header,
              caption: banners[i - 1].caption,
              link: banners[i - 1].link,
            };
          }
          // ao: sdaw-1375 if there's an uploaded image for banner1 or banner2 use it instead of default.
          for (let i = 1; i <= 5; i++) {
            //Banner Object is fixed to 5 banners (API)
            if (pageInfo[`file${i}`])
              parsedInfo[i] = {
                name: pageInfo[`name${i}`],
                text: pageInfo[`bannerText${i}`],
                textPosition: getTextPosition(pageInfo[`textStylePos${i}`]),
                fontSize: pageInfo[`textStyleFontSize${i}`] ? pageInfo[`textStyleFontSize${i}`] : 1,
                fontColor: pageInfo[`textStyleColor${i}`] ? pageInfo[`textStyleColor${i}`] : '#FFFFFF',
                seo: pageInfo[`seo${i}`] ? pageInfo[`seo${i}`] : false,
                link: pageInfo[`link${i}`],
                file: pageInfo[`file${i}`],
                mobile: pageInfo[`mobileFile${1}`],
                id: pageInfo._links.self.href,
                page: `${pageInfo.page}`,
              };
          }
          if (mounted)
            setState((prev) => ({
              ...prev,
              banners: {
                ...state.banners,
                formattedBanners: Object.values(parsedInfo),
                // topBanners: copy.data._embedded.topBanners, //[] What does this do? is it needed
              },
              loading: false,
              // apiState: { ...state.apiState, apiMessage: res?.status || 'Success', apiError: false },
              apiState: { ...state.apiState, apiMessage: 'Success', apiError: false },
            }));
        })
        .catch((e) => {
          console.log('API ERROR', e);
          throw e;
        });
    }
    if (Object.values(parsedInfo)?.length > 0) {
      if (mounted)
        setState((prev) => ({
          ...prev,
          banners: {
            ...state.banners,
            formattedBanners: Object.values(parsedInfo),
            // topBanners: copy.data._embedded.topBanners, //[] What does this do? is it needed
          },
          loading: false,
          // apiState: { ...state.apiState, apiMessage: res?.status || 'Success', apiError: false },
          apiState: { ...state.apiState, apiMessage: 'Success', apiError: false },
        }));
    } else {
      //means the backend call had no topBanners
      if (mounted)
        setState((prev) => ({
          ...prev,
          loading: false,
          // apiState: { ...state.apiState, apiMessage: res.status, apiError: true },
          apiState: { ...state.apiState, apiMessage: 'Success', apiError: true },
        }));
    }
    return () => {
      mounted = false;
    };
    // .catch((err) => setState((prev) => ({ ...prev, apiState: { ...state.apiState, apiError: true, error: err } })));
    // eslint-disable-next-line
  }, [previewBannerData]);

  // We need to display banners 1-5. IF a banner has a non null file attribute, we display it.
  // Considerations: SEO banners will be manually text styled and set by devs. All other regular banners are admin manageable
  // Text styling is also going to be a part of the formattedBanners.

  const LinkWrapper = ({ link, children }) => {
    /* Wraps the banner in an a tag to make the whole slide a clickable link.  
    if no link present, just gives the banner slide (children)*/
    if (link) {
      return (
        <a target="_blank" rel="noopener noreferrer" href={link}>
          {children}
        </a>
      );
    }
    return <>{children}</>;
  };

  return (
    <div className={classes.root} id="carouselWrapper">
      {state.banners?.formattedBanners?.length <= 0 && <Skeleton className={classes.root} animation="wave" />}
      {state.APIError && <Typography>There was an issue fetching banner information from the server.</Typography>}
      {!state.loading && !state.APIError && (
        <Fragment>
          <Carousel autoplay={true}>
            {state?.banners?.formattedBanners
              .filter((ban) => ban.file?.url)
              .map((banner, index) => {
                return (
                  <LinkWrapper link={banner.link} key={`banner-${index}`}>
                    <div className={classes.bannerSlide}>
                      <img src={banner.file.url} alt={banner.name} className={classes.bannerImage} />
                      {/* sdaw-1392 -  Remove the "See Details" button and make the banner clickable to navigate to the defined link. The content <div> is drawn on top of the image so it won't be covered by it, therefore, the <a> tag needs to wrap this <div> and not he <img> element. */}
                      <div
                        className={classes.content}
                        style={
                          banner.link ? { ...banner.textPosition } : { ...banner.textPosition, pointerEvents: 'none' }
                        }
                      >
                        <div className={classes.contentInnerContainer}>
                          <Typography
                            className={classes.bannerText}
                            variant={banner.name.slice(-1) === '1' ? 'h1' : 'h2'} //1st Banner will always be the H1 for the page (ref SEO)
                            style={{
                              color: `${banner.fontColor ? banner.fontColor : 'white'}`,
                            }}
                          >
                            {override && index === 0 ? override : banner.text}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </LinkWrapper>
                );
              })}
          </Carousel>
          <Divider className={classes.divider} />
        </Fragment>
      )}
    </div>
  );
}

export default CarouselPage;
