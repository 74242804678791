import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(8),
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
}));

function UnauthorizedView(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography caption="body1">
        You are not authorized to access this page.
      </Typography>
    </div>
  );
}

export default UnauthorizedView;
