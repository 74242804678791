import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
// import Rating from '@material-ui/lab/Rating';
import { FEATURED_TYPES, NAV_OPTIONS } from '../utils/constants';
import { makeImageKitURL } from '../utils/helpers';

import { Link } from 'react-router-dom';
import { parseIdFromLink, abbreviate, navbarTabLink, generateSlug } from '../utils/helpers';
import { Tooltip } from '@material-ui/core';
import RatingStars from './RatingStars';

import openSignIcon from '../assets/icons/openSign.svg';
import closedSignIcon from '../assets/icons/closedSign.svg';

const useStyles = makeStyles((theme) => ({
  featItemcard: {
    width: 200,
    [theme.breakpoints.down('md')]: {
      width: 160,
    },
    display: 'relative',
  },
  verifiedBadge: {
    color: '#00aced',
    height: 40,
    width: 40,
    position: 'absolute',
    top: -8,
    right: -6,
  },
  media: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    height: 200,
    [theme.breakpoints.down('md')]: {
      height: 160,
    },
  },
  cardText: {
    [theme.breakpoints.down('md')]: {
      fontSize: '75%',
    },
  },
  cardTextTitle: {
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      fontSize: '95%',
    },
  },
  rating: {
    display: 'flex',
  },
  stars: {
    marginRight: theme.spacing(1),
  },
  cardActionArea: {
    position: 'relative',
  },
  dealStoreImage: {
    height: 60,
    width: 60,
    position: 'absolute',
    bottom: theme.spacing(10),
    right: theme.spacing(1),
    objectFit: 'contain',
    border: '1px solid #ebebeb',
    borderRadius: '5px',
    backgroundColor: '#ebebeb',
    [theme.breakpoints.down('sm')]: {
      height: 50,
      width: 50,
    },
  },
}));

function FeaturedListItem(props) {
  const { item, onClick, type, query, verified, location, showOpenStatus } = props;
  const classes = useStyles();

  let openNowIndicator = null; //Only show when explicitly true - some pages with feat items don't show open status
  if (showOpenStatus === true) {
    openNowIndicator = item.isOpenNow ? (
      <img
        src={openSignIcon}
        alt="Open Now"
        style={{
          height: 50,
          width: 50,
          position: 'absolute',
          top: 12,
          right: 24,
          zIndex: 1,
        }}
      />
    ) : (
      <img
        src={closedSignIcon}
        alt="Closed Now"
        style={{
          height: 50,
          width: 50,
          position: 'absolute',
          top: 12,
          right: 24,
          zIndex: 1,
        }}
      />
    );
  }

  const itemID = parseIdFromLink(item._links.self.href, 0, '{?projection}');
  const itemSlug = item.slug ? item.slug : generateSlug(item.name);
  const itemPath = location
    ? `${navbarTabLink(
        NAV_OPTIONS.find((option) => option.name === type).path,
        location.province,
        location.city
      )}/${itemID}/${itemSlug}`
    : `${NAV_OPTIONS.find((option) => option.name === type).path}/${itemID}/${itemSlug}`;

  const getImagePath = (item, type) => {
    switch (type) {
      case FEATURED_TYPES.DEAL:
        return item.dealImage;
      case FEATURED_TYPES.BRAND:
        return item.brandImage;
      default:
        return item.storeImage;
    }
  };

  const imageURL = getImagePath(item, type);
  const imageKitURL = makeImageKitURL(imageURL, { width: '200' }, type);

  let storeLogoForDeal = '';
  if (type === FEATURED_TYPES.DEAL) {
    storeLogoForDeal = makeImageKitURL(item.storeImage, { width: '75' });
  }
  return (
    <Card
      className={classes.featItemcard}
      onClick={() => onClick()}
      component={Link}
      to={
        query
          ? {
              pathname: itemPath,
              query: query,
            }
          : itemPath
      }
      style={{ color: '#000000', textDecoration: 'none' }}
    >
      {openNowIndicator}

      <CardActionArea className={classes.cardActionArea}>
        {/* <CardMedia
          className={classes.media}
          image={imageURL}
          // image="https://dummyimage.com/200x200/000/fff"
          title={item.name}
        /> */}
        <CardMedia className={classes.media} image={imageKitURL} title={item.name} />
        <CardContent>
          <div
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width: '11rem',
            }}
          >
            <Typography noWrap variant="caption" className={classes.cardText}>
              {location
                ? `${location.city}, ${abbreviate(location.province)}`
                : type === FEATURED_TYPES.DEAL
                ? `${item.storeAddress.city}, ${abbreviate(item.storeAddress.province)}`
                : `${item.address.city}, ${abbreviate(item.address.province)}`}
            </Typography>
          </div>
          <Typography
            noWrap
            variant={type === FEATURED_TYPES.DEAL ? 'body2' : 'body1'}
            className={
              [FEATURED_TYPES.STORE, FEATURED_TYPES.DELIVERY, FEATURED_TYPES.CLINIC, FEATURED_TYPES.BRAND].includes(
                type
              )
                ? classes.cardTextTitle
                : classes.cardText
            }
          >
            {type === FEATURED_TYPES.DEAL ? item.storeName : item.name}
          </Typography>
          {type === FEATURED_TYPES.DEAL && (
            <Typography noWrap variant="body1" className={classes.cardTextTitle}>
              {item.name}
            </Typography>
          )}
          {[FEATURED_TYPES.STORE, FEATURED_TYPES.DELIVERY, FEATURED_TYPES.CLINIC, FEATURED_TYPES.BRAND].includes(
            type
          ) && <RatingStars avgRating={item.avgRating} numOfReviews={item.numOfReviews} />}
        </CardContent>
        {type === FEATURED_TYPES.DEAL && (
          <img src={storeLogoForDeal} className={classes.dealStoreImage} alt={item.storeName}></img>
        )}
      </CardActionArea>
      {verified && (
        <Tooltip className={classes.verifiedBadge} placement="top" arrow title="Verified Reseller">
          <img src={require(`../assets/badges/verifiedBlueCheck.svg`)} alt={`verified-blue-check`} />
        </Tooltip>
      )}
    </Card>
  );
}

export default FeaturedListItem;
